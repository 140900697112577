export const Permission = {
  HEARLINK_ADMIN: 'hearlink:admin',
  ORGANISATION_OWNER: 'organisation:owner',
  GROUPS_BROWSE: 'groups:browse',
  GROUPS_READ: 'groups:read',
  GROUPS_CREATE: 'groups:create',
  GROUPS_UPDATE: 'groups:update',
  GROUPS_DELETE: 'groups:delete',
  USERS_BROWSE: 'users:browse',
  USERS_READ: 'users:read',
  USERS_CREATE: 'users:create',
  USERS_UPDATE: 'users:update',
  USERS_DELETE: 'users:delete',
  APPOINTMENTS_BROWSE: 'appointments:browse',
  APPOINTMENTS_READ: 'appointments:read',
  APPOINTMENTS_CREATE: 'appointments:create',
  APPOINTMENTS_UPDATE: 'appointments:update',
  APPOINTMENTS_DELETE: 'appointments:delete',
  PATIENTS_BROWSE: 'patients:browse',
  PATIENTS_READ: 'patients:read',
  PATIENTS_CREATE: 'patients:create',
  PATIENTS_UPDATE: 'patients:update',
  PATIENTS_DELETE: 'patients:delete',
  CLINICS_BROWSE: 'clinics:browse',
  CLINICS_READ: 'clinics:read',
  CLINICS_CREATE: 'clinics:create',
  CLINICS_UPDATE: 'clinics:update',
  CLINICS_DELETE: 'clinics:delete',
  APPOINTMENT_TYPES_BROWSE: 'appointment_types:browse',
  APPOINTMENT_TYPES_READ: 'appointment_types:read',
  APPOINTMENT_TYPES_CREATE: 'appointment_types:create',
  APPOINTMENT_TYPES_UPDATE: 'appointment_types:update',
  APPOINTMENT_TYPES_DELETE: 'appointment_types:delete',
  LEAD_TYPES_BROWSE: 'lead_types:browse',
  LEAD_TYPES_CREATE: 'lead_types:create',
  LEAD_TYPES_UPDATE: 'lead_types:update',
  LEAD_TYPES_DELETE: 'lead_types:delete',
  FORM_SETTINGS_BROWSE: 'form_settings:browse',
  FORM_SETTINGS_UPDATE: 'form_settings:update',
  PRODUCTS_AND_SERVICES_BROWSE: 'products_services:browse',
  PRODUCTS_AND_SERVICES_CREATE: 'products_services:create',
  PRODUCTS_AND_SERVICES_UPDATE: 'products_services:update',
  PRODUCTS_AND_SERVICES_DELETE: 'products_services:delete',
  AUDIOMETERS_BROWSE: 'audiometers:browse',
  AUDIOMETERS_CREATE: 'audiometers:create',
  AUDIOMETERS_UPDATE: 'audiometers:update',
  AUDIOMETERS_DELETE: 'audiometers:delete',
  AUDIT_LOG_BROWSE: 'audit_log:browse',
  AUDIT_LOG_READ: 'audit_log:read',
  WORKFLOW_BROWSE: 'workflow:browse',
  WORKFLOW_UPDATE: 'workflow:update',
};

export const groupPermissionSections = [
  {
    key: 'groups',
    labelTranslationKey: 'navigation.groups',
    permissions: [
      Permission.GROUPS_BROWSE,
      Permission.GROUPS_READ,
      Permission.GROUPS_CREATE,
      Permission.GROUPS_UPDATE,
      Permission.GROUPS_DELETE,
    ],
  },
  {
    key: 'clinics',
    labelTranslationKey: 'navigation.clinics',
    permissions: [
      Permission.CLINICS_BROWSE,
      Permission.CLINICS_READ,
      Permission.CLINICS_CREATE,
      Permission.CLINICS_UPDATE,
      Permission.CLINICS_DELETE,
    ],
  },
  {
    key: 'appointmentTypes',
    labelTranslationKey: 'navigation.appointment_types',
    permissions: [
      Permission.APPOINTMENT_TYPES_BROWSE,
      Permission.APPOINTMENT_TYPES_READ,
      Permission.APPOINTMENT_TYPES_CREATE,
      Permission.APPOINTMENT_TYPES_UPDATE,
      Permission.APPOINTMENT_TYPES_DELETE,
    ],
  },
  {
    key: 'leadTypes',
    labelTranslationKey: 'navigation.lead_types',
    permissions: [
      Permission.LEAD_TYPES_BROWSE,
      Permission.LEAD_TYPES_CREATE,
      Permission.LEAD_TYPES_UPDATE,
      Permission.LEAD_TYPES_DELETE,
    ],
  },
  {
    key: 'formSettings',
    labelTranslationKey: 'navigation.form_settings',
    permissions: [Permission.FORM_SETTINGS_BROWSE, Permission.FORM_SETTINGS_UPDATE],
  },
  {
    key: 'productsAndServices',
    labelTranslationKey: 'navigation.products_and_services',
    permissions: [
      Permission.PRODUCTS_AND_SERVICES_BROWSE,
      Permission.PRODUCTS_AND_SERVICES_CREATE,
      Permission.PRODUCTS_AND_SERVICES_UPDATE,
      Permission.PRODUCTS_AND_SERVICES_DELETE,
    ],
  },
  {
    key: 'workflow',
    labelTranslationKey: 'navigation.workflow',
    permissions: [Permission.WORKFLOW_BROWSE, Permission.WORKFLOW_UPDATE],
  },
  {
    key: 'users',
    labelTranslationKey: 'navigation.users',
    permissions: [
      Permission.USERS_BROWSE,
      Permission.USERS_READ,
      Permission.USERS_CREATE,
      Permission.USERS_UPDATE,
      Permission.USERS_DELETE,
    ],
  },
  {
    key: 'appointments',
    labelTranslationKey: 'navigation.appointments',
    permissions: [
      Permission.APPOINTMENTS_BROWSE,
      Permission.APPOINTMENTS_READ,
      Permission.APPOINTMENTS_CREATE,
      Permission.APPOINTMENTS_UPDATE,
      Permission.APPOINTMENTS_DELETE,
    ],
  },
  {
    key: 'patients',
    labelTranslationKey: 'navigation.patients',
    permissions: [
      Permission.PATIENTS_BROWSE,
      Permission.PATIENTS_READ,
      Permission.PATIENTS_CREATE,
      Permission.PATIENTS_UPDATE,
      Permission.PATIENTS_DELETE,
    ],
  },
  {
    key: 'auditLog',
    labelTranslationKey: 'navigation.audit_log',
    permissions: [Permission.AUDIT_LOG_BROWSE, Permission.AUDIT_LOG_READ],
  },
];
