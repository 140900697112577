import { IAuditLogDao } from 'core/api/types';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { useUserState } from 'core/providers/user-provider';
import { collection, orderBy, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AuditLogResource from './audit-log-resource';
import { Tag } from 'antd';
import dayjs from 'dayjs';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'audit_log.table.header.user',
    key: 'user',
    contentTemplateId: 'user',
  },
  {
    labelKey: 'audit_log.table.header.resource',
    key: 'resource',
    contentTemplateId: 'resource',
  },
  {
    labelKey: 'audit_log.table.header.action',
    key: 'action',
    contentTemplateId: 'action',
  },
  {
    labelKey: 'audit_log.table.header.associated_resources',
    key: 'associations',
    contentTemplateId: 'associations',
  },
  {
    labelKey: 'audit_log.table.header.timestamp',
    key: 'timestamp',
    contentTemplateId: 'timestamp',
  },
];

const AuditLog = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'audit_log';

  const userTemplate = (log: IAuditLogDao) => log.created.by.fullName;

  const resourceTemplate = (log: IAuditLogDao) => <AuditLogResource {...log.resource} />;

  const actionTemplate = (log: IAuditLogDao) => {
    let action = '';
    let color = '';
    switch (log.actionType) {
      case 'create':
        action = t('audit_log.action.create');
        color = 'green';
        break;
      case 'update':
        action = t('audit_log.action.update');
        color = 'blue';
        break;
      case 'delete':
        action = t('audit_log.action.delete');
        color = 'red';
        break;
    }
    return (
      <Tag className='uppercase font-semibold' color={color}>
        {action}
      </Tag>
    );
  };

  const associationsTemplate = (log: IAuditLogDao) => (
    <div>
      {!log.associations || log.associations.length === 0 ? (
        <p className='text-gray-300'>{t('common.na')}</p>
      ) : (
        log.associations.map((association) => <AuditLogResource {...association} />)
      )}
    </div>
  );

  const timestampTemplate = (log: IAuditLogDao) => dayjs(log.updated.at.toDate()).format('DD/MM/YYYY, HH:mm');

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: userTemplate,
      id: 'user',
    },
    {
      template: resourceTemplate,
      id: 'resource',
    },
    {
      template: actionTemplate,
      id: 'action',
    },
    {
      template: associationsTemplate,
      id: 'associations',
    },
    {
      template: timestampTemplate,
      id: 'timestamp',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.audit_log')} />
      <SharedCard className='mb-4'>
        {userData && (
          <SharedPaginatedTable
            collectionRef={collection(firestore, CollectionID.AUDIT_LOG)}
            queryConstraints={[where('organisationUid', '==', userData?.organisationUid)]}
            queryOrder={orderBy('updated.at', 'desc')}
            tableConfig={{
              columns: tableColumns,
              contentTemplates,
            }}
            errorMessageKey='audit_log.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AuditLog;
