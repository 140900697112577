import { StringIndexedAnyValue } from 'shared/helpers/interface.helpers';
import { ISharedTableBody, ISharedTableCustomTemplate } from './table.interface';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { useTranslation } from 'react-i18next';

const SharedTableBody = <T extends StringIndexedAnyValue>({
  columns,
  contentTemplates,
  rows,
  initializing,
}: ISharedTableBody<T>) => {
  const { t } = useTranslation();
  const textCellTemplate = (data: string) => <p className='whitespace-pre-wrap body-sm'>{data ?? t('common.na')}</p>;
  const templates: ISharedTableCustomTemplate[] = [
    {
      template: textCellTemplate,
      id: 'text',
    },
    ...contentTemplates,
  ];

  const findTemplate = (rowData: T, templateId: string, columnKey: string) => {
    const contentTemplate = templates.find((template) => template.id === templateId);
    return contentTemplate?.template(templateId === 'text' ? rowData[columnKey] : rowData);
  };

  return (
    <tbody>
      {initializing ? (
        <tr className='border-t min-h-[56px]'>
          {columns.map(({ key }) => (
            <td className='p-3' key={key}>
              <SkeletonElement width='50%' height='1.25rem' />
            </td>
          ))}
        </tr>
      ) : (
        <>
          {rows.map((row) => (
            <tr key={row.key} className='border-t hover:bg-gray-50 min-h-[56px]'>
              {columns.map(({ key, contentTemplateId = 'text' }) => (
                <td className='p-3 body-sm' key={key}>
                  {findTemplate(row.data, contentTemplateId ?? 'text', key)}
                </td>
              ))}
            </tr>
          ))}
        </>
      )}
    </tbody>
  );
};

export default SharedTableBody;
