import { App } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import SharedAuthPageWrapper from './auth-page-wrapper';
import { useTranslation } from 'react-i18next';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import SharedButton from 'shared/button/button';

interface IForgotPasswordFormOutput {
  emailAddress: string;
}

const ForgotPassword = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  const fields: ISharedField[] = [
    {
      fieldKey: 'emailAddress',
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('common.email_address'),
      required: true,
    },
  ];

  const onFinish = async (data: IForgotPasswordFormOutput) => {
    try {
      setFormSubmitting(true);
      await sendPasswordResetEmail(auth, data.emailAddress);
      setSuccess(true);
    } catch (error) {
      setFormSubmitting(false);
      message.error(t('auth.forgot_password.error'));
    }
  };

  return (
    <SharedAuthPageWrapper>
      {success ? (
        <div>
          <p>{t('auth.forgot_password.success')}</p>
          <SharedButton
            labelKey='auth.return_to_login'
            className='mt-4'
            fullWidth
            onClick={() => navigate('/auth/login')}
          />
        </div>
      ) : (
        <>
          <p className='mb-6'>{t('auth.forgot_password.instruction')}</p>
          <SharedForm<IForgotPasswordFormOutput>
            onFinish={onFinish}
            fields={fields}
            submitting={formSubmitting}
            buttonLabelKey='common.submit'
            name='forgot-password'
            className='p-0'
            cancelButton={{
              labelKey: 'auth.return_to_login',
              onClick: () => navigate('/auth/login'),
            }}
          />
        </>
      )}
    </SharedAuthPageWrapper>
  );
};

export default ForgotPassword;
