import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserState } from 'core/providers/user-provider';
import SharedSpinner from 'shared/spinner/spinner';
import AppointmentsRouting from 'modules/appointments/appointments-routing';
import AdminRouting from 'modules/admin/admin-routing';
import AdminGuard from './admin-guard';
import AuthGuard from './auth-guard';
import PermissionGuard from './permission-guard';
import { Permission } from 'core/constants/permission';
import OrganisationSettingsRouting from 'modules/organisation-settings/organisation-settings-routing';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import AuthRouting from 'modules/auth/auth-routing';
import NoPermissions from 'shared/layout/no-permissions';
import PatientsRouting from 'modules/patients/patients-routing';

const AppRouting = () => {
  const { authChecked } = useUserState();

  if (!authChecked) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <SharedSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<AuthGuard requiresAuth={false} />}>
        <Route path='auth/*' element={<AuthRouting />} />
      </Route>
      <Route element={<AuthGuard requiresAuth={true} />}>
        <Route element={<AdminGuard requiresAdmin={true} />}>
          <Route path='admin/*' element={<AdminRouting />} />
        </Route>

        <Route element={<AdminGuard requiresAdmin={false} />}>
          <Route path='no-permissions' element={<NoPermissions />} />

          <Route
            element={
              <PermissionGuard
                requiredPermissions={[[Permission.APPOINTMENTS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              />
            }
          >
            <Route path='appointments/*' element={<AppointmentsRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='patients/*' element={<PatientsRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard
                requiredPermissions={[
                  ...OrganisationSettingsNavbarOptions.flatMap((option) => option.requiredPermissions),
                  [Permission.ORGANISATION_OWNER],
                ]}
              />
            }
          >
            <Route path='organisation-settings/*' element={<OrganisationSettingsRouting />} />
          </Route>
        </Route>
      </Route>
      <Route path='' element={<Navigate replace to={'appointments'} />} />
      <Route path='*' element={<Navigate replace to={'appointments'} />} />
    </Routes>
  );
};

export default AppRouting;
