import { createContext, useCallback, useContext, useState } from 'react';

interface ITableDataContext {
  tables: ITableDataContextTablesState;
  register: (tableData: ITableDataContextTableState) => void;
}

interface ITableDataContextTableState {
  key: string;
  refreshTable: () => Promise<void>;
}

interface ITableDataContextTablesState {
  [key: string]: ITableDataContextTableState;
}

export const TableDataContext = createContext<ITableDataContext>({
  tables: {},
  register: () => {},
});

export const useTablesState = () => {
  const state = useContext(TableDataContext);
  return state;
};

export const useTable = (tableId: string) => {
  const state = useContext(TableDataContext);
  return state.tables[tableId];
};

export const TableDataProvider = ({ children }: any) => {
  const [tables, setTables] = useState<ITableDataContextTablesState>({});

  const register = useCallback((tableData: ITableDataContextTableState) => {
    return setTables((prevState) => ({ ...prevState, [tableData.key]: tableData }));
  }, []);

  return <TableDataContext.Provider value={{ tables, register }}>{children}</TableDataContext.Provider>;
};
