import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedCard from 'shared/card/card';
import { CollectionID } from 'core/constants/collection-id';
import { collection, where, orderBy } from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { IAudiometerDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import dayjs from 'dayjs';
import AddEditAudiometersDialog from './add-edit-audiometers-dialog';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'audiometers_list.table.header.make',
    key: 'make',
  },
  {
    labelKey: 'audiometers_list.table.header.model',
    key: 'model',
  },
  {
    labelKey: 'audiometers_list.table.header.serial_number',
    key: 'serialNumber',
  },
  {
    labelKey: 'audiometers_list.table.header.calibration_date',
    key: 'calibrationDate',
    contentTemplateId: 'calibrationDate',
  },
  { key: 'updated', labelKey: 'audiometers_list.table.header.updated', contentTemplateId: 'updated' },
  { key: 'action', contentTemplateId: 'actions' },
];

const updatedTemplate = (data: IAudiometerDao) => {
  return (
    <div className='flex space-x-2 items-center'>
      <p>
        {dayjs(data.updated.at.toDate()).format('DD/MM/YYYY')} by {data.updated.by.fullName}
      </p>
    </div>
  );
};

const calibrationDateTemplate = (data: IAudiometerDao) => {
  return (
    <div className='flex space-x-2 items-center'>
      <p>{dayjs(data.calibrationDate.toDate()).format('DD/MM/YYYY')}</p>
    </div>
  );
};

const AudiometersList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'audiometers_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.AUDIOMETERS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='audiometers_list.header.add_audiometer'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditAudiometersDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAudiometer',
    },
  ];

  const actionTemplate = (audiometer: IAudiometerDao) => {
    return (
      <div className='w-full flex justify-end space-x-2'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.AUDIOMETERS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditAudiometersDialog existing={audiometer} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      id: 'calibrationDate',
      template: calibrationDateTemplate,
    },
    {
      id: 'updated',
      template: updatedTemplate,
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <SharedPageHeader showBack title={t('audiometers')} actions={headerActions} />
      <SharedCard className='mb-4'>
        {userData && (
          <SharedPaginatedTable
            collectionRef={collection(firestore, CollectionID.AUDIOMETERS)}
            queryConstraints={[where('organisationUid', '==', userData.organisationUid), where('deleted', '==', false)]}
            queryOrder={orderBy('updated.at', 'desc')}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='audiometers_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AudiometersList;
