export enum CollectionID {
  USERS = 'users',
  ORGANISATIONS = 'organisations',
  GROUPS = 'groups',
  CLINICS = 'clinics',
  APPOINTMENT_TYPES = 'appointmentTypes',
  AUDIT_LOG = 'auditLog',
  LEAD_TYPES = 'leadTypes',
  PATIENT_FORM_SETTINGS = 'patientFormSettings',
  PATIENTS = 'patients',
  ORDER_FORM_SETTINGS = 'orderFormSettings',
  HEARING_AIDS = 'hearingAids',
  ACCESSORIES = 'accessories',
  SERVICES = 'services',
  AUDIOMETERS = 'audiometers',
  PATIENT_WORKFLOWS = 'patientWorkflows',
}
