import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Select, Switch, Tag } from 'antd';
import { IWorkflowStatusDao } from 'core/api/types/workflow.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import { ArrowDown, ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import AddEditWorkflowStatusDialog, { IAddEditStatusFormOutput } from './add-edit-workflow-status-dialog';
import { useState } from 'react';

interface IWorkflowStatusDraggable {
  currentState: IWorkflowStatusDao;
  allStatuses: IWorkflowStatusDao[];
  updateStatus: (status: IWorkflowStatusDao) => void;
}

const WorkflowStatusDraggable = ({ currentState, allStatuses, updateStatus }: IWorkflowStatusDraggable) => {
  const { t } = useTranslation();
  const [selectOpen, setSelectOpen] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled: selectOpen,
    id: currentState.key,
  });
  const { primary } = useTheme();
  const dialog = useDialog();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const allSelected = currentState.canTransitionTo === 'all';

  const saveStatusNameUpdate = (data: IAddEditStatusFormOutput) => {
    updateStatus({ ...currentState, ...data });
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className='flex flex-col space-y-2 items-start border-b last:border-0 md:border-0 pb-6 md:pb-0 md:grid md:grid-cols-7 md:items-center mb-6 md:mb-3 last:mb-0 gap-2'
    >
      <div className='col-span-3 group flex items-center justify-between'>
        <Tag color={primary.bg}>{currentState.name}</Tag>
        <div data-no-dnd='true'>
          <SharedButton
            labelKey='common.edit'
            appearance='link'
            primaryOverride
            className='md:hidden md:group-hover:block'
            onClick={() =>
              dialog?.openDialog(
                <AddEditWorkflowStatusDialog onFinish={saveStatusNameUpdate} existing={currentState} />
              )
            }
          />
        </div>
      </div>

      <ArrowRight className='col-span-1 text-gray-400 hidden md:block' size={20} />
      <ArrowDown className='col-span-1 text-gray-400 block md:hidden' size={20} />
      <div
        className='w-full md:col-span-3 flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4'
        data-no-dnd='true'
      >
        <div className='flex space-x-1 items-center'>
          <p className='body-xs'>{t('workflow.all_statuses')}</p>
          <Switch
            value={currentState.canTransitionTo === 'all'}
            size='small'
            onChange={(checked) => updateStatus({ ...currentState, canTransitionTo: checked ? 'all' : [] })}
          />
        </div>
        <Select
          onDropdownVisibleChange={(open) => setSelectOpen(open)}
          disabled={allSelected}
          className='md:flex-grow'
          value={allSelected ? [] : (currentState.canTransitionTo as string[])}
          mode='multiple'
          options={allStatuses
            .filter((status) => status.key !== currentState.key)
            .map((status) => ({
              value: status.key,
              label: status.name,
            }))}
          placeholder={!allSelected && t('workflow.workflow_editor.select_transitions')}
          onChange={(value: string[]) => {
            updateStatus({ ...currentState, canTransitionTo: value });
          }}
        />
      </div>
    </div>
  );
};
export default WorkflowStatusDraggable;
