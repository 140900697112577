import { NavbarOptions } from 'core/config/navbar-options';
import SharedSidebarItem from './sidebar-item';
import { Drawer } from 'antd';
import { Dispatch } from 'react';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { useTheme } from 'core/providers/theme-provider';

interface ISharedSidebar {
  mobileOpen: boolean;
  setOpenMobileMenu: Dispatch<React.SetStateAction<boolean>>;
}

const SharedSidebar = ({ mobileOpen = false, setOpenMobileMenu }: ISharedSidebar) => {
  const { primary, logoWhite } = useTheme();
  const { userPermissions } = usePermissionsState();
  const navItemsToShow = NavbarOptions.filter((item) =>
    item.requiredPermissions.some((permissions) =>
      permissions.every((permission) => userPermissions?.includes(permission))
    )
  );
  const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;

  const onClose = () => {
    setOpenMobileMenu(false);
  };

  const sidebarContent = (
    <div className='flex flex-col h-full'>
      <div className='h-[60px] md:h-[70px] border-b border-white/10 flex items-center justify-between px-4'>
        <img src={logoWhite} alt='logo-white' className='max-h-[60%] md:max-h-[40%]' />
      </div>
      <div className='flex flex-col  space-y-2 p-4 flex-grow overflow-y-auto'>
        {navItemsToShow.map((item) => (
          <SharedSidebarItem key={item.route} {...item} onClick={() => setOpenMobileMenu(false)} />
        ))}
      </div>
      {releaseVersion && <p className='p-4 text-white/50'>{releaseVersion}</p>}
    </div>
  );

  const drawerStyles = {
    content: {
      backgroundColor: primary.bg,
    },
    body: {
      padding: 0,
    },
  };

  return (
    <>
      <div
        className='hidden md:block basis-[280px] flex-grow-0 flex-shrink-0 z-10'
        style={{ backgroundColor: primary.bg }}
      >
        {sidebarContent}
      </div>
      <div className='block md:hidden'>
        <Drawer styles={drawerStyles} placement='left' closable={false} onClose={onClose} open={mobileOpen}>
          {sidebarContent}
        </Drawer>
      </div>
    </>
  );
};

export default SharedSidebar;
