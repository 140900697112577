import { onAuthStateChanged, signOut, Unsubscribe, User } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'core/config/firebase';
import { UsersApiService } from 'core/api';
import { IUserDao } from 'core/api/types';
import { OrganisationsApiService } from 'core/api';
import { IOrganisationDao } from 'core/api/types';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

interface IUserContext {
  user: User | null;
  error?: Error;
  authChecked: boolean;
  userData?: IUserDao;
  organisationData?: IOrganisationDao;
}

export const UserContext = createContext<IUserContext | null>(null);

export const useUserState = () => {
  const state = useContext(UserContext);
  return { ...state, isAuthenticated: state?.user !== null };
};

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error>();
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserDao>();
  const [organisationData, setOrganisationData] = useState<IOrganisationDao>();
  const { message } = App.useApp();
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribeFromAuth = onAuthStateChanged(
      auth,
      async (user) => {
        setUser(user);
        setTimeout(() => {
          setAuthChecked(true);
        }, 1000);
      },
      setError
    );
    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  useEffect(() => {
    let unsubscribeFromUserDoc: Unsubscribe;
    if (user) {
      unsubscribeFromUserDoc = UsersApiService.onDocSnapshot(
        user.uid,
        (snap) => setUserData(snap.data()),
        () => signOut(auth)
      );
    } else {
      setUserData(undefined);
    }

    return () => {
      if (unsubscribeFromUserDoc) {
        unsubscribeFromUserDoc();
      }
    };
  }, [user]);

  useEffect(() => {
    let unsubscribeFromOrgDoc: Unsubscribe;
    if (userData && userData.organisationUid) {
      unsubscribeFromOrgDoc = OrganisationsApiService.onDocSnapshot(
        userData.organisationUid,
        (snap) => setOrganisationData(snap.data()),
        () => {
          signOut(auth);
          message.error(t('misc.organisation.error'));
        }
      );
    } else {
      setOrganisationData(undefined);
    }

    return () => {
      if (unsubscribeFromOrgDoc) {
        unsubscribeFromOrgDoc();
      }
    };
  }, [message, t, userData]);

  return (
    <UserContext.Provider value={{ user, error, authChecked, userData, organisationData }}>
      {children}
    </UserContext.Provider>
  );
};
