import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFormSettingsDao, ILeadTypeDao } from 'core/api/types';
import { IWorkflowDao } from 'core/api/types/workflow.interface';
import { IListDataSlice, IObjectDataSlice, StoreState } from 'store';

export type IDomainOrganisationFormSettings = Omit<IFormSettingsDao, 'updated'> & {
  updatedAtSeconds: number;
};

export type IDomainOrganisationDataType<T> = Omit<T, 'updated' | 'created'> & {
  updatedAtSeconds: number;
  createdAtSeconds: number;
};

export interface IOrganisationSettings {
  formSettings: {
    patient?: IObjectDataSlice<IDomainOrganisationFormSettings>;
    order?: IObjectDataSlice<IDomainOrganisationFormSettings>;
  };
  leadTypes: IListDataSlice<IDomainOrganisationDataType<ILeadTypeDao>>;
  patientWorkflow: IObjectDataSlice<IDomainOrganisationDataType<IWorkflowDao>>;
}

const initialState: Partial<IOrganisationSettings> = {
  leadTypes: {
    status: 'loading',
    data: [],
  },
};

const slice = createSlice({
  name: 'organisationSettings',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    updatePatientFormSettings(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationFormSettings>>) {
      state.formSettings = {
        ...state.formSettings,
        patient: action.payload,
      };
    },
    updateLeadTypes(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<ILeadTypeDao>>>) {
      state.leadTypes = action.payload;
    },
    updateOrderFormSettings(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationFormSettings>>) {
      state.formSettings = {
        ...state.formSettings,
        order: action.payload,
      };
    },
    updatePatientWorkflow(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationDataType<IWorkflowDao>>>) {
      state.patientWorkflow = action.payload;
    },
  },
});

const { reset, updatePatientFormSettings, updateLeadTypes, updateOrderFormSettings, updatePatientWorkflow } =
  slice.actions;

const selectState = (state: StoreState) => state.organisationSettings;

const selectPatientFormSettings = (state: StoreState) => state.organisationSettings.formSettings?.patient;

const selectLeadTypes = (state: StoreState) => state.organisationSettings.leadTypes;

const selectOrderFormSettings = (state: StoreState) => state.organisationSettings.formSettings?.order;

const selectPatientWorkflow = (state: StoreState) => state.organisationSettings.patientWorkflow;

export const OrganisationSettingsSlice = {
  reset,
  updatePatientFormSettings,
  updateLeadTypes,
  updateOrderFormSettings,
  updatePatientWorkflow,
  selectState,
  selectPatientFormSettings,
  selectLeadTypes,
  selectOrderFormSettings,
  selectPatientWorkflow,
};

export default slice.reducer;
