import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentTypesList from './appointment-types-list';

const AppointmentTypesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AppointmentTypesList />} />

      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AppointmentTypesRouting;
