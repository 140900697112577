import { useTheme } from 'core/providers/theme-provider';
import { useEffect, useState } from 'react';
import { useUserState } from 'core/providers/user-provider';
import { useDialog } from 'core/providers/dialog-provider';
import SetPasswordDialog from 'shared/dialog/set-password-dialog';
import SharedSidebar from 'shared/sidebar/sidebar';
import { Menu } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import SharedButton from 'shared/button/button';
import { SearchOutlined } from '@ant-design/icons';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';

const SharedLayout = ({ children }: any) => {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { primary } = useTheme();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (!dialog?.showDialog && userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
    }
  }, [dialog, userData?.resetPassword]);

  const logOut = () => {
    signOut(auth);
  };

  return (
    <div className='flex flex-col md:flex-row'>
      <SharedSidebar mobileOpen={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />

      {/* Mobile header  */}
      <div
        className='md:hidden h-[60px] flex items-center justify-between px-4 fixed w-full z-10'
        style={{ backgroundColor: primary.bg }}
      >
        <Menu color={primary.text} onClick={() => setOpenMobileMenu(true)} />
      </div>

      <div className='flex flex-col w-full pt-[60px] md:pt-0' style={{ height: screenHeight }}>
        <div className='basis-[60px] md:basis-[70px] flex-grow-0 flex-shrink-0 border-b bg-white flex items-center justify-between md:justify-end px-4'>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_BROWSE]]}
          >
            {!userData?.isHearLinkAdmin && (
              <SharedButton
                labelKey='patients.patient_search'
                icon={<SearchOutlined />}
                onClick={() => dialog?.openDialog(<PatientSearchDialog />)}
              />
            )}
          </SharedElementPermissionGuard>

          <div className='flex flex-col items-end text-gray-800 ml-6'>
            <p className='body-sm'>{userData?.fullName}</p>
            <button className='body-sm opacity-60 hover:underline bg-transparent' onClick={() => logOut()}>
              {t('common.log_out')}
            </button>
          </div>
        </div>
        <div
          className={clsx(
            'flex-grow overflow-y-auto max-h-full',
            !location.pathname.includes('organisation-settings') && 'px-4'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
