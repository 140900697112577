export const OrganisationStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
};

export const OrganisationStatusData = {
  [OrganisationStatus.ACTIVE]: {
    value: OrganisationStatus.ACTIVE,
    translationLabelKey: 'organisation.status.active',
    color: '#16a34a',
  },
  [OrganisationStatus.INACTIVE]: {
    value: OrganisationStatus.INACTIVE,
    translationLabelKey: 'organisation.status.inactive',
    color: '#f97316',
  },
  [OrganisationStatus.DELETED]: {
    value: OrganisationStatus.DELETED,
    translationLabelKey: 'organisation.status.deleted',
    color: '#dc2626',
  },
};
