import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        // Common
        'common.email_address': 'Email address',
        'common.password': 'Password',
        'common.full_name': 'Full name',
        'common.users': 'Users',
        'common.actions': 'Actions',
        'common.user_type': 'User type',
        'common.user_type.member': 'Member',
        'common.user_type.hearlink_admin': 'HearLink Admin',
        'common.user_type.organisation_owner': 'Organisation Owner',
        'common.submit': 'Submit',
        'common.save': 'Save',
        'common.cancel': 'Cancel',
        'common.close': 'Close',
        'common.previous': 'Previous',
        'common.next': 'Next',
        'common.loading_with_ellipsis': 'Loading...',
        'common.na': 'N/A',
        'common.log_out': 'Log out',
        'common.organisation': 'Organisation',
        'common.delete': 'Delete',
        'common.edit': 'Edit',
        'common.unknown': 'Unknown',
        'common.name': 'Name',
        'common.description': 'Description',
        'common.view': 'View',
        'common.back': 'Back',
        'common.reset': 'Reset',
        'common.no_results': 'No results',
        'common.an_error_occurred': 'An error occurred',
        'common.address': 'Address',
        'common.deactivate': 'Deactivate',
        'common.activate': 'Activate',
        'common.enable': 'Enable',
        'common.disable': 'Disable',
        // Authentication
        'auth.login.incorrect_email_password': 'Incorrect email address or password',
        'auth.login': 'Log in',
        'auth.login.instruction': 'Log in to your account',
        'auth.forgot_password': 'Forgot password',
        'auth.forgot_password.instruction':
          "Enter the email address associated with your account, and we'll send you a link to reset your password.",
        'auth.forgot_password.success':
          "Thanks, if the email address you entered is associated with an account, you will receive an email with instructions to reset your password. If you can't find the email, be sure to check your junk mailbox",
        'auth.forgot_password.error': 'An error occurred, please check your email address input and try again',
        'auth.return_to_login': 'Return to login',
        'auth.reset_password.title': 'Reset password',
        'auth.reset_password.instruction': 'Enter your new password below',
        'auth.reset_password.invalid_link': 'Invalid reset password link',
        'auth.reset_password.success': 'Your password has been reset successfully',
        'auth.reset_password.error': 'Password reset failed, please try again',
        'auth.new_password': 'New password',
        'auth.confirm_new_password': 'Confirm new password',
        'auth.reset_password.password_mismatch': 'Passwords do not match',
        // Navigation
        'navigation.organisations': 'Organisations',
        'navigation.users': 'Users',
        'navigation.appointments': 'Appointments',
        'navigation.groups': 'Groups',
        'navigation.patients': 'Patients',
        'navigation.organisation_settings': 'Organisation Settings',
        'navigation.clinics': 'Clinics',
        'navigation.appointment_types': 'Appointment Types',
        'navigation.lead_types': 'Lead Types',
        'navigation.form_settings': 'Form Settings',
        'navigation.products_and_services': 'Products & Services',
        'navigation.audiometers': 'Audiometers',
        'navigation.audit_log': 'Audit Log',
        'navigation.workflow': 'Workflow',
        // Fields
        'field.required': 'This field is required.',
        'field.file.too_large': "We're sorry, but this file is too large",
        'field.file.button': 'Choose file',
        'field.file.file_type_not_accepted': 'This file type is not accepted',
        'field.file.max_files_reached':
          'You have reached the maximum number of files for this input, please remove a file to add another',
        'field.select.placeholder': 'Select an option',
        'field.user_search.placeholder': "Start typing a user's name",
        'field.user_search.no_results': 'No users found',
        'field.user_search.error.description':
          'The user search could not be performed due to an error, please try again.',
        'field.address_search.placeholder': 'Search for an address',
        'field.address_search.search_button': 'Search',
        'field.address_search.error.description':
          'This address has missing/invalid data, please try again with a different address, if the problem continues, contact your administrator.',
        'field.address_search.autocomplete_error_error.description':
          'We could not retrieve the address suggestions for your query, please try again.',
        'field.address_search.searching_indicator': 'Searching...',
        'field.address_search.search_full_error.description':
          'We failed to get the full details for this address, please try again.',
        // Dialogs
        'dialog.new_user_password.copy_password': 'Copy password',
        'dialog.new_user_password.done': 'Done',
        'dialog.new_user_password.title': 'User created',
        'dialog.new_user_password.body':
          "A new user account has been created for <1>{{fullName}}</1>. The credentials below can be used to access their account. They'll be asked to set their own password on first log in.",
        'dialog.new_user_password.success.description':
          'Password copied. The generated password was copied to the clipboard.',
        'dialog.set_password.error.generic': 'An error has occurred, if this persists, contact your administrator.',
        'dialog.set_password.error.passwords_mismatch': 'New passwords do not match',
        'dialog.set_password.instruction':
          'You are required to set a new password in order to continue. You will be asked to login after your new password has been set',
        'dialog.delete_user.title': 'Delete {{userName}}?',
        'dialog.delete_user.content': 'Are you sure you want to delete this user? This action cannot be undone.',
        'dialog.delete_user.error.description': 'An error occurred when trying to delete this user, please try again.',
        'dialog.delete_user.success.description': 'The user was successfully deleted.',
        'dialog.add_edit_user.create.title': 'Invite a new user',
        'dialog.add_edit_user.edit.title': 'Edit user',
        'dialog.add_edit_user.create.error.description': 'User creation failed due to an error, please try again.',
        'dialog.add_edit_user.edit.success.description': 'The user was successfully updated.',
        'dialog.add_edit_user.edit.error.description':
          'The user could not be updated due to an error, please try again.',
        'dialog.add_group.create.title': 'Add a new group',
        'dialog.add_group.create.error.description':
          'The group could not be created due to an error, please try again.',
        'dialog.add_group.create.success.description': 'The group was successfully created.',
        'dialog.add_group.edit.title': 'Edit group details',
        'dialog.add_group.edit.error.description':
          'The group could not be updated due to an error, please check your permissions and try again.',
        'dialog.add_group.edit.success.description': 'The group details were successfully updated',
        'dialog.add_group_member.error.description':
          'The member(s) could not be added due to an error, please try again.',
        'dialog.remove_group_member.title': 'Remove member from group?',
        'dialog.remove_group_member.content': 'Are you sure you want to remove {{userName}} from this group?',
        'dialog.remove_group_member.success.description': 'The member was successfully removed from the group',
        'dialog.remove_group_member.error.description':
          'The member could not be removed due to an error, please check your permissions and try again',
        'dialog.add_edit_clinic.create.title': 'Add a new clinic',
        'dialog.add_edit_clinic.form.invalid_address': 'You must select a valid address from the results list',
        'dialog.add_clinic.create.error.description':
          'The clinic could not be created due to an error, please try again.',
        'dialog.add_clinic.edit.error.description':
          'The clinic could not be updated due to an error, please try again.',
        'dialog.add_clinic.create.success.description': 'The clinic was successfully created.',
        'dialog.add_edit_clinic.edit.title': 'Edit clinic',
        'dialog.add_clinic.edit.success.description': 'The clinic was successfully updated.',
        'dialog.delete_clinic.success.description': 'The clinic was successfully deleted.',
        'dialog.delete_clinic.error.description': 'Clinic deletion failed, please check your permissions and try again',
        'dialog.delete_clinic.title': 'Delete {{clinicName}}?',
        'dialog.delete_clinic.content': 'Are you sure you want to delete this clinic? This action cannot be undone.',
        'dialog.add_edit_appointment_type.create.title': 'Add a new appointment type',
        'dialog.add_edit_appointment_type.form.colour.title': 'Colour',
        'dialog.add_edit_appointment_type.form.duration.title': 'Duration (minutes)',
        'dialog.add_edit_appointment_type.create.success.description': 'The appointment type was successfully created.',
        'dialog.add_edit_appointment_type.create.edit.description': 'The appointment type was successfully updated.',
        'dialog.add_edit_appointment_type.create.error.description':
          'Appointment type creation failed, please check your permissions and try again',
        'dialog.add_edit_lead_type.create.title': 'Add a new lead type',
        'dialog.add_edit_lead_type.edit.title': 'Edit lead type',
        'dialog.add_edit_lead_type.create.success.description': 'The lead type was successfully created.',
        'dialog.add_edit_lead_type.edit.success.description': 'The lead type was successfully updated.',
        'dialog.delete_lead_type.title': 'Delete {{leadTypeName}}?',
        'dialog.delete_lead_type.content':
          'Are you sure you want to delete this lead type? This action cannot be undone.',
        'dialog.delete_lead_type.success.description': 'The lead type was successfully deleted.',
        'dialog.delete_lead_type.error.description':
          'Lead type deletion failed, please check your permissions and try again',
        'dialog.add_edit_hearing_aids.create.title': 'Add a new hearing aid',
        'dialog.add_edit_hearing_aids.edit.title': 'Edit hearing aid',
        'dialog.add_edit_hearing_aids.create.success.description': 'The hearing aid was successfully created.',
        'dialog.add_edit_hearing_aids.create.error.description':
          'Hearing aid creation failed, please check your permissions and try again',
        'dialog.add_edit_hearing_aids.edit.success.description': 'The hearing aid was successfully updated.',
        'dialog.add_edit_hearing_aids.edit.error.description':
          'Hearing aid update failed, please check your permissions and try again',
        'dialog.delete_hearing_aids.title': 'Delete this hearing aid?',
        'dialog.delete_hearing_aids.content':
          'Are you sure you want to delete this hearing aid? This action cannot be undone.',
        'dialog.delete_hearing_aids.success.description': 'The hearing aid was successfully deleted.',
        'dialog.delete_hearing_aids.error.description':
          'Hearing aid deletion failed, please check your permissions and try again',
        'dialog.add_edit_audiometers.create.title': 'Create audiometer',
        'dialog.add_edit_audiometers.edit.title': 'Edit audiometer',
        'dialog.add_edit_audiometers.create.success.description': 'The audiometer was successfully created.',
        'dialog.add_edit_audiometers.edit.success.description': 'The audiometer was successfully updated.',
        'dialog.add_edit_audiometers.create.error.description':
          'Audiometer creation failed, please check your permissions and try again',
        'dialog.add_edit_audiometers.edit.error.description':
          'Audiometer update failed, please check your permissions and try again',
        'dialog.add_edit_accessories.create.title': 'Add a new accessory',
        'dialog.add_edit_accessories.edit.title': 'Edit accessory',
        'dialog.add_edit_accessories.create.success.description': 'The accessory was successfully created.',
        'dialog.add_edit_accessories.create.error.description':
          'Accessory creation failed, please check your permissions and try again',
        'dialog.add_edit_accessories.edit.success.description': 'The accessory was successfully updated.',
        'dialog.add_edit_accessories.edit.error.description':
          'Accessory update failed, please check your permissions and try again',
        'dialog.add_edit_accessories.title': 'Delete this accessory?',
        'dialog.add_edit_accessories.content':
          'Are you sure you want to delete this accessory? This action cannot be undone.',
        'dialog.add_edit_accessories.success.description': 'The accessory was successfully deleted.',
        'dialog.add_edit_accessories.error.description':
          'Accessory deletion failed, please check your permissions and try again',
        'dialog.delete_accessories.title': 'Delete this accessory?',
        'dialog.delete_accessories.content':
          'Are you sure you want to delete this accessory? This action cannot be undone.',
        'dialog.delete_accessories.success.description': 'The accessory was successfully deleted.',
        'dialog.delete_accessories.error.description':
          'Accessory deletion failed, please check your permissions and try again',
        'dialog.add_edit_services.create.title': 'Add a new service',
        'dialog.add_edit_services.edit.title': 'Edit service',
        'dialog.add_edit_services.create.success.description': 'The service was successfully created.',
        'dialog.add_edit_services.create.error.description':
          'Service creation failed, please check your permissions and try again',
        'dialog.add_edit_services.edit.success.description': 'The service was successfully updated.',
        'dialog.add_edit_services.edit.error.description':
          'Service update failed, please check your permissions and try again',
        'dialog.add_edit_services.title': 'Delete this service?',
        'dialog.add_edit_services.content':
          'Are you sure you want to delete this service? This action cannot be undone.',
        'dialog.add_edit_services.success.description': 'The service was successfully deleted.',
        'dialog.add_edit_services.error.description':
          'Service deletion failed, please check your permissions and try again',
        'dialog.delete_services.title': 'Delete this service?',
        'dialog.delete_services.content': 'Are you sure you want to delete this service? This action cannot be undone.',
        'dialog.delete_services.success.description': 'The service was successfully deleted.',
        'dialog.delete_services.error.description':
          'Service deletion failed, please check your permissions and try again',
        // Admin
        // Organisations
        'admin.organisations_list.header.title': 'Organisations',
        'admin.organisations_list.header.add_organisation': 'Add organisation',
        'admin.organisations_list.table.header.name': 'Name',
        'admin.organisations_list.table.header.owner': 'Owner',
        'admin.organisations_list.table.header.status': 'Status',
        'admin.organisations_list.table.action.reset_settings': 'Reset settings',
        'admin.add_edit_organisation.name': 'Name',
        'admin.add_edit_organisation.logo_white': 'Logo (White)',
        'admin.add_edit_organisation.background_colour': 'Background colour',
        'admin.add_edit_organisation.text_colour': 'Text colour',
        'admin.add_edit_organisation.title.creating': 'Create an organisation',
        'admin.add_edit_organisation.title.editing': 'Edit organisation',
        'admin.add_edit_organisation.colour_placeholder': '#FFFFFF',
        'admin.add_edit_organisation.colour_error': 'Please enter a valid colour code.',
        'admin.add_edit_organisation.create.error.description':
          'The organisation could not be created, please check your permissions and try again',
        'admin.add_edit_organisation.edit.error.description':
          'The organisation could not be updated, please check your permissions and try again',
        'admin.add_edit_organisation.create.success.description': 'The organisation was successfully created',
        'admin.add_edit_organisation.edit.success.description': 'The organisation was successfully updated',
        'admin.add_edit_organisation.logo.fetch_error': 'Failed to fetch logo, please try again',
        'organisation.status.active': 'Active',
        'organisation.status.inactive': 'Inactive',
        'organisation.status.deleted': 'Deleted',
        'dialog.deactivate_organisation.title': 'Deactivate {{orgName}}?',
        'dialog.deactivate_organisation.content': 'Are you sure you want to deactivate this organisation?',
        'dialog.deactivate_organisation.error':
          'The organisation could not be deactivated, please check your permissions and try again',
        'dialog.deactivate_organisation.success': 'The organisation was successfully deactivated',
        'dialog.activate_organisation.title': 'Activate {{orgName}}?',
        'dialog.activate_organisation.content': 'Are you sure you want to activate this organisation?',
        'dialog.activate_organisation.error':
          'The organisation could not be activated, please check your permissions and try again',
        'dialog.activate_organisation.success': 'The organisation was successfully activated',
        // Users
        'admin.users_list.table.header.user': 'User',
        'admin.users_list.table.header.user_type': 'User type',
        'admin.users_list.header.add_user': 'Add user',
        'admin.users_list.table.error.description': 'User data could not be loaded, try again',
        // Non-admin
        // Patients
        'patients.patients_dashboard.header.add_patient': 'Add patient',
        'patients.add_edit_patient.create.title': 'Add a new patient',
        'patients.add_edit_patient.patient_form_settings_error':
          "We're having trouble loading the patient form settings, please check your permissions and try again",
        'patients.add_edit_patient.create.error':
          'Patient creation failed, please check your permissions and try again',
        'patients.add_edit_patient.create.success': 'The patient was successfully created',
        'patients.patient_search': 'Patient search',
        'patients.patient_search.placeholder': 'Search for a patient',
        'patients.patient_search.no_results': 'No patients found',
        'patients.patient_search.error': 'Patient search failed',
        // Users
        'users.users_list.table.error.description': 'User data could not be loaded, try again',
        'users.users_list.table.header.user': 'User',
        'users.users_list.header.add_user': 'Add user',
        // Settings
        // Groups
        'groups.groups_list.table.header.group': 'Group',
        'groups.groups_list.table.header.members': 'Members',
        'groups.groups_list.table.header.permissions': 'Permissions',
        'groups.groups_list.table.group_cell.no_description': 'No description',
        'groups.groups_list.table.error.description': 'Group data could not be loaded, try again',
        'groups.groups_list.header.add_group': 'Add group',
        'groups.group_detail.error.description':
          'This groups information could not be loaded due to an error, please check your permissions and try again.',
        'groups.group_detail.header.edit_details': 'Edit group details',
        'groups.group_detail.permissions.title': 'Group permissions',
        'groups.group_detail.permissions.section.no_permissions': 'No permissions assigned',
        'groups.group_detail.permissions.error.description':
          'The group permissions could not be saved due to an error, please try again.',
        'groups.group_detail.permissions.success.description': 'The group permissions were successfully saved.',
        'groups.group_detail.members.title': 'Group members',
        'groups.group_detail.members.add_member': 'Add member',
        'groups.group_detail.members.no_members': 'There are no members in this group',
        'groups.group_detail.members.error.description':
          'Group member details could not be loaded, please check your permissions and try again.',
        'groups.group_detail.members.remove_member': 'Remove',
        // Clinics
        'clinics.clinics_list.table.header.name': 'Name',
        'clinics.clinics_list.table.header.address': 'Address',
        'clinics.clinics_list.header.add_clinic': 'Add clinic',
        'clinics.clinics_list.table.error.description':
          'The clinics could not be retrieved from the server, please check your permissions and try again.',
        // Appointment types
        'appointment_types.appointment_types_list.table.header.name': 'Name',
        'appointment_types.appointment_types_list.table.header.colour': 'Colour',
        'appointment_types.appointment_types_list.table.header.duration': 'Duration (Minutes)',
        'appointment_types.appointment_types_list.table.error.description':
          'The appointment types could not be retrieved from the server, please check your permissions and try again.',
        'appointment_types.appointment_types_list.header.add_appointment_type': 'Add appointment type',
        // Lead types
        'lead_types.lead_types_list.table.header.name': 'Name',
        'lead_types.lead_types_list.header.add_lead_type': 'Add lead type',
        'lead_types.lead_types_list.table.error.description':
          'The lead types could not be retrieved from the server, please check your permissions and try again.',
        // Form settings
        'form_settings.form_name': 'Form name',
        'form_settings.patient_form': 'Patient form',
        'form_settings.order_form': 'Order form',
        'form_settings.add_custom_field': 'Add custom field',
        'form_settings.edit_custom_field': 'Edit custom field',
        'form_settings.custom_field': 'Custom field',
        'form_settings.field_label': 'Field label',
        'form_settings.add_custom_field.error': 'The custom field could not be added, please try again',
        'form_settings.edit_custom_field.error': 'The custom field could not be updated, please try again',
        'form_settings.edit_custom_field.success': 'The custom field was successfully updated',
        'form_settings.add_custom_field.success': 'The custom field was successfully added',
        'form_settings.custom_field.delete.success': 'The custom field was successfully deleted',
        'form_settings.custom_field.delete.error': 'The custom field could not be deleted, please try again',
        'form_settings.custom_field.delete.title': 'Delete custom field?',
        'form_settings.custom_field.delete.content': 'Are you sure you want to delete this custom field?',
        'form_settings.edit_patient_form': 'Edit patient form settings',
        'form_settings.edit_order_form': 'Edit order form settings',
        // Patient form
        'form_settings.patient_form.referral': 'Referral / Lead type',
        'form_settings.patient_form.title': 'Title',
        'form_settings.patient_form.full_name': 'Full name',
        'form_settings.patient_form.email_address': 'Email address',
        'form_settings.patient_form.primary_phone_number': 'Primary phone number',
        'form_settings.patient_form.secondary_phone_number': 'Secondary phone number',
        'form_settings.patient_form.address': 'Address',
        'form_settings.patient_form.dob': 'Date of birth',
        'form_settings.patient_form.gender': 'Gender',
        'form_settings.patient_form.marital_status': 'Marital status',
        'form_settings.patient_form.emergency_contact_name': 'Next of kin / Emergency contact name',
        'form_settings.patient_form.emergency_contact_number': 'Next of kin / Emergency contact number',
        'form_settings.patient_form.gp_details': 'GP details',
        'form_settings.patient_form.contact_preference': 'Contact preference',
        'form_settings.patient_form.contact_permissions': 'Contact permissions',
        'form_settings.patient_form.edit.error':
          'The field setting could not be updated, please check your permissions and try again',
        'form_settings.patient_form.edit.success': 'The field setting was successfully updated',
        'form_settings.patient_form.error_loading_data':
          'Patient form settings could not be loaded, please check your permissions and try again',
        // Order Form
        'form_settings.order_form.audiologist_name': 'Audiologist name',
        'form_settings.order_form.patient_name': 'Patient name',
        'form_settings.order_form.address': 'Address',
        'form_settings.order_form.email_address': 'Email address',
        'form_settings.order_form.phone_number': 'Phone number',
        'form_settings.order_form.order_date': 'Order date',
        'form_settings.order_form.order_number': 'Order/Invoice number',
        'form_settings.order_form.goods_services': 'Goods/Services',
        'form_settings.order_form.discount_insurance': 'Discount/Insurance',
        'form_settings.order_form.total_price': 'Total price',
        'form_settings.order_form.deposit': 'Deposit',
        'form_settings.order_form.audiologist_verification': 'Audiologist verification',
        'form_settings.order_form.payment_method': 'Payment method',
        'form_settings.order_form.customer_declaration': 'Customer declaration',
        'form_settings.order_form.customer_verification': 'Customer verification',
        'form_settings.order_form.additional_information': 'Additional information',
        'form_settings.order_form.cancelation_policy': 'Cancelation policy',
        'form_settings.order_form.edit.error':
          'The field setting could not be updated, please check your permissions and try again',
        'form_settings.order_form.edit.success': 'The field setting was successfully updated',
        'form_settings.order_form.error_loading_data':
          'Order form settings could not be loaded, please check your permissions and try again',
        'form_settings.order_form.manual': 'Manual',
        'form_settings.order_form.signature': 'Signature',
        // Products and services
        'products_and_services.hearing_aids': 'Hearing aids',
        'products_and_services.accessories': 'Accessories',
        'products_and_services.services': 'Services',
        // Products and Services - Hearing Aids - Table
        'products_and_services.hearing_aids_list.table.header.manufacturer': 'Manufacturer',
        'products_and_services.hearing_aids_list.table.header.model': 'Model',
        'products_and_services.hearing_aids_list.table.header.style': 'Style',
        'products_and_services.hearing_aids_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.hearing_aids_list.table.header.rrp': 'RRP',
        'products_and_services.hearing_aids_list.header.add_hearing_aid': 'Add hearing aid',
        'products_and_services.hearing_aids_list.table.error.description':
          'Hearing aids could not be loaded, please check your permissions and try again.',
        // Products and Services - Accessories - Table
        'products_and_services.accessories_list.table.header.manufacturer': 'Manufacturer',
        'products_and_services.accessories_list.table.header.accessory_name': 'Accessory name',
        'products_and_services.accessories_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.accessories_list.table.header.rrp': 'RRP',
        'products_and_services.accessories_list.header.add_accessory': 'Add accessory',
        'products_and_services.accessories_list.table.error.description':
          'Accessories could not be loaded, please check your permissions and try again.',
        // Products and Services - Services - Table
        'products_and_services.services_list.table.header.service_name': 'Service name',
        'products_and_services.services_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.services_list.table.header.rrp': 'RRP',
        'products_and_services.services_list.header.add_service': 'Add service',
        'products_and_services.services_list.table.error.description':
          'Services could not be loaded, please check your permissions and try again.',
        // Audit log
        'audit_log.header.title': 'Audit log',
        'audit_log.table.header.user': 'User',
        'audit_log.table.header.resource': 'Resource',
        'audit_log.table.header.action': 'Action',
        'audit_log.table.header.associated_resources': 'Associated resources',
        'audit_log.table.header.timestamp': 'Timestamp',
        'audit_log.table.error.description':
          'The audit log could not be loaded, please check your permissions and try again.',
        'audit_log.resource_type.appointmentType': 'Appointment type',
        'audit_log.resource_type.clinic': 'Clinic',
        'audit_log.resource_type.leadType': 'Lead type',
        'audit_log.resource_type.user': 'User',
        'audit_log.resource_type.patient': 'Patient',
        'audit_log.resource_type.group': 'Group',
        'audit_log.resource_type.appointment': 'Appointment',
        'audit_log.resource_type.organisation': 'Organisation',
        'audit_log.action.create': 'Create',
        'audit_log.action.update': 'Update',
        'audit_log.action.delete': 'Delete',
        // Products and Services - Hearing Aids - Add/Edit
        'products_and_services.hearing_aids.add_edit_hearing_aids.manufacturer': 'Manufacturer',
        'products_and_services.hearing_aids.add_edit_hearing_aids.model': 'Model',
        'products_and_services.hearing_aids.add_edit_hearing_aids.style': 'Style',
        'products_and_services.hearing_aids.add_edit_hearing_aids.receivers': 'Receivers',
        'products_and_services.hearing_aids.add_edit_hearing_aids.gain_options': 'Gain options',
        'products_and_services.hearing_aids.add_edit_hearing_aids.earbud_size': 'Earbud size',
        'products_and_services.hearing_aids.add_edit_hearing_aids.earbud_type': 'Earbud type',
        'products_and_services.hearing_aids.add_edit_hearing_aids.power': 'Power',
        'products_and_services.hearing_aids.add_edit_hearing_aids.unit_cost': 'Unit cost',
        'products_and_services.hearing_aids.add_edit_hearing_aids.rrp': 'RRP',
        'products_and_services.hearing_aids.add_edit_hearing_aids.warranty': 'Warranty',
        // Products and Services - Accessories - Add/Edit
        'products_and_services.accessories.add_edit_accessories.manufacturer': 'Manufacturer',
        'products_and_services.accessories.add_edit_accessories.accessory_name': 'Accessory name',
        'products_and_services.accessories.add_edit_accessories.unit_cost': 'Unit cost',
        'products_and_services.accessories.add_edit_accessories.rrp': 'RRP',
        'products_and_services.accessories.add_edit_accessories.warranty': 'Warranty',
        // Products and Services - Services - Add/Edit
        'products_and_services.services.add_edit_services.service_name': 'Service name',
        'products_and_services.services.add_edit_services.unit_cost': 'Unit cost',
        'products_and_services.services.add_edit_services.rrp': 'RRP',
        // Audiometers
        audiometers: 'Audiometers',
        'audiometers_list.table.header.make': 'Make',
        'audiometers_list.table.header.model': 'Model',
        'audiometers_list.table.header.serial_number': 'Serial number',
        'audiometers_list.table.header.calibration_date': 'Calibration date',
        'audiometers_list.table.header.updated': 'Updated',
        'audiometers_list.header.add_audiometer': 'Add audiometer',
        'audiometers_list.table.error.description':
          'Audiometers could not be loaded, please check your permissions and try again.',
        'audiometers.add_edit_audiometers.make': 'Make',
        'audiometers.add_edit_audiometers.model': 'Model',
        'audiometers.add_edit_audiometers.serial_number': 'Serial number',
        'audiometers.add_edit_audiometers.calibration_date': 'Calibration date',
        // Workflow
        'workflow.header.edit_workflow': 'Edit workflow',
        'workflow.workflow_editor': 'Workflow editor',
        'workflow.workflow_editor.update.success': 'The workflow was successfully updated',
        'workflow.workflow_editor.add_status': 'Add status',
        'workflow.workflow_editor.add_edit_status.create.title': 'Add a new status',
        'workflow.workflow_editor.add_edit_status.edit.title': 'Edit status',
        'workflow.workflow_editor.add_edit_status.name': 'Status name',
        'workflow.no_statuses': 'No statuses have been added to this workflow',
        'workflow.workflow_editor.select_transitions': 'Select statuses to transition to',
        'workflow.all_statuses': 'All statuses',
        'workflow.error_loading_data': 'Workflow data could not be loaded, please try again',
        // Permissions
        'permissions.groups_browse.label':
          'Access to browse groups within the organisation settings area of the website',
        'permissions.groups_read.label': 'Access to group information from database',
        'permissions.groups_create.label': 'Access to create new groups',
        'permissions.groups_update.label': 'Access to update all groups',
        'permissions.groups_delete.label': 'Access to delete any group',
        'permissions.users_browse.label': 'Access to browse users via the website',
        'permissions.users_read.label': 'Access to user information from database',
        'permissions.users_create.label': 'Access to create new users',
        'permissions.users_update.label': 'Access to update all users',
        'permissions.users_delete.label': 'Access to delete any group',
        'permissions.appointments_browse.label': 'Access to browse appointments via the website',
        'permissions.appointments_read.label': 'Access to appointment information from database',
        'permissions.appointments_create.label': 'Access to create new appointments',
        'permissions.appointments_update.label': 'Access to update all appointments',
        'permissions.appointments_delete.label': 'Access to delete any group',
        'permissions.patients_browse.label': 'Access to browse patients via the website',
        'permissions.patients_read.label': 'Access to patient information from database',
        'permissions.patients_create.label': 'Access to create new patients',
        'permissions.patients_update.label': 'Access to update all patients',
        'permissions.patients_delete.label': 'Access to delete any patient',
        'permissions.clinics_browse.label':
          'Access to browse clinics within the organisation settings area of the website',
        'permissions.clinics_read.label': 'Access to clinic information from database',
        'permissions.clinics_create.label': 'Access to create new clinics',
        'permissions.clinics_update.label': 'Access to update all clinics',
        'permissions.clinics_delete.label': 'Access to delete any clinic',
        'permissions.appointment_types_browse.label':
          'Access to browse appointment types within the organisation settings area of the website',
        'permissions.appointment_types_read.label': 'Access to appointment type information from database',
        'permissions.appointment_types_create.label': 'Access to create new appointment types',
        'permissions.appointment_types_update.label': 'Access to update all appointment types',
        'permissions.appointment_types_delete.label': 'Access to delete any appointment type',
        'permissions.lead_types_browse.label':
          'Access to browse lead types within the organisation settings area of the website',
        'permissions.lead_types_create.label': 'Access to create new lead types',
        'permissions.lead_types_update.label': 'Access to update all lead types',
        'permissions.lead_types_delete.label': 'Access to delete any lead type',
        'permissions.form_settings_browse.label':
          'Access to browse form settings within the organisation settings area of the website',
        'permissions.form_settings_read.label': 'Access to form setting information from database',
        'permissions.form_settings_update.label': 'Access to update all form settings',
        'permissions.products_services_browse.label': 'Access to browse products and services via the website',
        'permissions.products_services_read.label': 'Access to product and service information from database',
        'permissions.products_services_create.label': 'Access to create new products and services',
        'permissions.products_services_update.label': 'Access to update all products and services',
        'permissions.products_services_delete.label': 'Access to delete any product or service',
        'permissions.audit_log_browse.label': 'Access to browse audit logs via the website',
        'permissions.audit_log_read.label': 'Access to audit log information from database',
        // Misc
        'no_permissions.title': 'No permissions',
        'no_permissions.description':
          'Your account has no permissions assigned, please contact your organisation administrator',
        'misc.permissions.error': 'Your session has expired, please log in again',
        'misc.organisation.error':
          'Your organisation has been suspended from HearLink. If you suspect this is an error, please contact support.',

        // Title
        'title.mr': 'Mr',
        'title.mrs': 'Mrs',
        'title.miss': 'Miss',
        'title.ms': 'Ms',
        'title.dr': 'Dr',
        'title.other': 'Other',
        // Contact methods
        'contact_method.email': 'Email',
        'contact_method.phone': 'Phone',
        'contact_method.sms': 'SMS',
        // Gender
        'gender.male': 'Male',
        'gender.female': 'Female',
        'gender.non_binary': 'Non-binary',
        'gender.prefer_not_to_say': 'Prefer not to say',
        // Marital status
        'marital_status.single': 'Single',
        'marital_status.married': 'Married',
        'marital_status.divorced': 'Divorced',
        'marital_status.widowed': 'Widowed',
        'marital_status.domestic_partnership': 'Domestic partnership',
        'marital_status.civil_union': 'Civil union',
        'marital_status.separated': 'Separated',
        'marital_status.prefer_not_to_say': 'Prefer not to say',
      },
    },
  },
});

export default i18next;
