import { usePermissionsState } from 'core/providers/permissions-provider';

interface ISharedPermissionGuard {
  requiredPermissions: string[][];
  children: any;
}

const SharedElementPermissionGuard = ({ children, requiredPermissions }: ISharedPermissionGuard) => {
  const { userPermissions } = usePermissionsState();

  if (
    requiredPermissions.some((permissions) => permissions.every((permission) => userPermissions?.includes(permission)))
  ) {
    return <>{children}</>;
  }

  return <></>;
};

export default SharedElementPermissionGuard;
