import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import { CheckCircleTwoTone, LockTwoTone, CloseCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import SharedButton from 'shared/button/button';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { useState } from 'react';
import { PatientFormSettingsApiService } from 'core/api';
import { CollectionID } from 'core/constants/collection-id';
import { useUserState } from 'core/providers/user-provider';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { Alert, App } from 'antd';
import { useDialog } from 'core/providers/dialog-provider';
import DefaultFieldSetting from './default-field-setting';
import CustomFieldSetting from './custom-field-setting';
import FormSettingsAddEditCustomField from './form-settings-add-edit-custom-field-dialog';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { patientFormDefaultFields } from 'core/constants/organisation-forms';

const EditPatientFormSettings = () => {
  const patientFormSettings = useSelector(OrganisationSettingsSlice.selectPatientFormSettings);
  const { t } = useTranslation();
  const [fieldsLoading, setFieldsLoading] = useState<string[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();
  const errorState = !patientFormSettings || !patientFormSettings.data || patientFormSettings.status === 'error';
  const activeCustomFields = patientFormSettings?.data?.customFields.filter((field) => !field.deleted);

  const toggleSetting = async (key: string, isDisabled: boolean) => {
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation UID not found');
      }
      setFieldsLoading((prevState) => [...prevState, key]);
      await PatientFormSettingsApiService.update(userData.organisationUid, {
        disabledFields: isDisabled ? arrayRemove(key) : arrayUnion(key),
        updated: getActionTimestampFromUser(userData),
      });
      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));
      message.success(t('form_settings.patient_form.edit.success'));
    } catch (error) {
      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));
      message.error(t('form_settings.patient_form.edit.error'));
    }
  };

  const getFieldIcon = (isDisabled: boolean, isLocked: boolean) => {
    let icon = <CheckCircleTwoTone twoToneColor='#22c55e' />;

    if (isDisabled) {
      icon = <CloseCircleTwoTone twoToneColor='#ef4444' />;
    } else if (isLocked) {
      icon = <LockTwoTone twoToneColor='#a3a3a3' />;
    }

    return icon;
  };

  const isFieldDisabled = (fieldKey: string) => patientFormSettings?.data?.disabledFields.includes(fieldKey) ?? false;

  return (
    <>
      <SharedPageHeader title={t('form_settings.edit_patient_form')} showBack />
      {errorState ? (
        <Alert message={t('form_settings.patient_form.error_loading_data')} type='error' showIcon />
      ) : (
        <SharedCard>
          <>
            {patientFormDefaultFields.map((field) => {
              const isDisabled = isFieldDisabled(field.key);

              return (
                <DefaultFieldSetting
                  key={field.key}
                  toggleSetting={toggleSetting}
                  fieldKey={field.key}
                  labelKey={field.labelKey}
                  isDisabled={isDisabled}
                  isLocked={field.locked}
                  isLoading={fieldsLoading.includes(field.key)}
                  statusIcon={getFieldIcon(isDisabled, field.locked)}
                />
              );
            })}

            {activeCustomFields?.map((field) => {
              const isDisabled = isFieldDisabled(field.key);

              return (
                <CustomFieldSetting
                  key={field.key}
                  collectionID={CollectionID.PATIENT_FORM_SETTINGS}
                  customField={field}
                  isDisabled={isDisabled}
                  isLoading={fieldsLoading.includes(field.key)}
                  toggleSetting={toggleSetting}
                  statusIcon={getFieldIcon(isDisabled, false)}
                />
              );
            })}

            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.FORM_SETTINGS_UPDATE]]}
            >
              <div className='p-4'>
                <SharedButton
                  labelKey='form_settings.add_custom_field'
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    dialog?.openDialog(
                      <FormSettingsAddEditCustomField collectionID={CollectionID.PATIENT_FORM_SETTINGS} />
                    )
                  }
                />
              </div>
            </SharedElementPermissionGuard>
          </>
        </SharedCard>
      )}
    </>
  );
};

export default EditPatientFormSettings;
