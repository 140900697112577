import { App, Empty, Input } from 'antd';
import { useTheme } from 'core/providers/theme-provider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedSpinner from 'shared/spinner/spinner';
import SharedCard from 'shared/card/card';
import { PatientApiService } from 'core/api';
import { IPatientSearchResult } from 'core/api/types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useDialog } from 'core/providers/dialog-provider';
import SharedButton from 'shared/button/button';
import { CloseOutlined } from '@ant-design/icons';

const PatientSearchDialog = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<IPatientSearchResult[]>();
  const [searching, setSearching] = useState(false);
  const { message } = App.useApp();
  const { primary } = useTheme();
  const dialog = useDialog();

  const performSearch = useCallback(
    async (term: string) => {
      try {
        setResults(undefined);
        setSearching(true);
        const results = await PatientApiService.search(term);
        setResults(results);
        setSearching(false);
      } catch (error) {
        message.error(t('patients.patient_search.error'));
        setSearching(false);
      }
    },
    [message, t]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAutocomplete = useCallback(
    _.debounce((term: string) => performSearch(term), 500),
    []
  );

  const onTextChanged = (value: string) => {
    setQuery(value);

    if (value.trim() === '') {
      setResults(undefined);
      debouncedAutocomplete.cancel();
      return;
    }

    setSearching(true);
    debouncedAutocomplete(value);
  };

  return (
    <div className='flex flex-col items-center space-y-6 w-full'>
      <SharedButton shape='circle' icon={<CloseOutlined />} onClick={() => dialog?.closeDialog()} appearance='dashed' />
      <SharedCard className='w-full md:w-[600px] lg:w-[700px] '>
        <div className='p-4'>
          <Input
            onChange={(event) => onTextChanged(event.currentTarget.value)}
            value={query}
            placeholder={t('patients.patient_search.placeholder')}
            autoFocus
            allowClear
          />
        </div>
        <div className='h-[400px] border-t flex justify-center overflow-y-auto'>
          {searching ? (
            <div className='self-center'>
              <SharedSpinner color={primary.bg} />
            </div>
          ) : (
            <>
              {results && results.length === 0 && (
                <Empty
                  className='self-center'
                  description={t('patients.patient_search.no_results')}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}

              {results && results.length > 0 && (
                <ul className='w-full'>
                  {results?.map((result) => (
                    <li key={result.objectID}>
                      <Link to={`/patients/${result.objectID}`} onClick={() => dialog?.closeDialog()}>
                        <div className='px-4 py-3 border-b text-gray-600 hover:bg-gray-50 transition ease-in-out'>
                          <p className='font-semibold text-gray-900'>{result.fullName}</p>
                          {result.address && <p>{result.address.formattedAddress}</p>}
                          {result.emailAddress && <p>{result.emailAddress}</p>}
                          <p>
                            {result.phoneNumber && result.phoneNumber}{' '}
                            {result.secondaryPhoneNumber && `• ${result.secondaryPhoneNumber}`}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}

              {!results && <p className='text-gray-300 self-center'>{t('patients.patient_search.placeholder')}</p>}
            </>
          )}
        </div>
      </SharedCard>
    </div>
  );
};

export default PatientSearchDialog;
