export const Gender = {
  MALE: 'male',
  FEMALE: 'female',
  NON_BINARY: 'nonBinary',
  PREFER_NOT_TO_SAY: 'preferNotToSay',
};

export const GenderData = {
  [Gender.MALE]: {
    value: Gender.MALE,
    translationLabelKey: 'gender.male',
  },
  [Gender.FEMALE]: {
    value: Gender.FEMALE,
    translationLabelKey: 'gender.female',
  },
  [Gender.NON_BINARY]: {
    value: Gender.NON_BINARY,
    translationLabelKey: 'gender.non_binary',
  },
  [Gender.PREFER_NOT_TO_SAY]: {
    value: Gender.PREFER_NOT_TO_SAY,
    translationLabelKey: 'gender.prefer_not_to_say',
  },
};

export const GenderOptions = [Gender.MALE, Gender.FEMALE, Gender.NON_BINARY, Gender.PREFER_NOT_TO_SAY];
