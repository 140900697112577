import { Permission } from 'core/constants/permission';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Select } from 'antd';
import SetTextInputDialog from 'shared/dialog/text-input-dialog';
import { useDialog } from 'core/providers/dialog-provider';

interface IDefaultFieldSetting {
  toggleSetting: (key: string, isDisabled: boolean) => Promise<void>;
  setValue?: (key: string, values: string) => Promise<void>;
  fieldKey: string;
  labelKey: string;
  fieldValue?: string;
  isDisabled: boolean;
  isLocked: boolean;
  isTextInput?: boolean;
  isLoading: boolean;
  statusIcon: ReactNode;
  selectOptions?: { value: string; label: string }[];
}

const DefaultFieldSetting = ({
  toggleSetting,
  setValue,
  fieldKey,
  labelKey,
  fieldValue,
  isDisabled,
  isLoading,
  isLocked,
  isTextInput,
  statusIcon,
  selectOptions,
}: IDefaultFieldSetting) => {
  const { t } = useTranslation();
  const dialog = useDialog();

  const options = selectOptions?.map((option) => ({ value: option.value, label: t(option.label) }));

  return (
    <div className='border-b p-4 flex justify-between last:border-b-0' key={fieldKey}>
      <div className='flex items-center space-x-2'>
        {statusIcon}
        <p>{t(labelKey)}</p>
      </div>
      {!isLocked && (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.FORM_SETTINGS_UPDATE]]}
        >
          {(options && setValue && (
            <Select
              className='min-w-[100px]'
              defaultValue={fieldValue ?? 'signature'}
              options={options}
              onChange={(value) => setValue(fieldKey, value)}
            />
          )) ||
            (isTextInput && setValue && (
              <SharedButton
                appearance='link'
                primaryOverride
                labelKey={'common.edit'}
                onClick={() =>
                  dialog?.openDialog(
                    <SetTextInputDialog
                      title={t(labelKey)}
                      fieldKey={fieldKey}
                      defaultValue={fieldValue}
                      onFinish={(value) => {
                        setValue(fieldKey, value);
                        dialog?.closeDialog();
                      }}
                    />
                  )
                }
                loading={isLoading}
              />
            )) || (
              <SharedButton
                appearance='link'
                primaryOverride
                labelKey={isDisabled ? 'common.enable' : 'common.disable'}
                onClick={() => toggleSetting(fieldKey, isDisabled)}
                loading={isLoading}
              />
            )}
        </SharedElementPermissionGuard>
      )}
    </div>
  );
};

export default DefaultFieldSetting;
