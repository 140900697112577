import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { ILeadTypeDao } from 'core/api/types';
import { LeadTypesApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';

const nameFieldKey = 'name';
interface IAddLeadTypeFormOutput {
  [nameFieldKey]: string;
}
interface IAddEditLeadTypeDialog {
  existing?: ILeadTypeDao;
  tableKey: string;
}
const AddEditLeadTypeDialog = ({ existing, tableKey }: IAddEditLeadTypeDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const creating = !existing;
  const table = useTable(tableKey);

  const AddLeadTypeFormFields: ISharedField[] = [
    {
      fieldKey: nameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
  ];

  const submit = async (data: IAddLeadTypeFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error("This user's organisationUid is missing or invalid");
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        updated: userTimestamp,
        name: data[nameFieldKey],
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await LeadTypesApiService.set({
          uid: uuidv4(),
          created: userTimestamp,
          deleted: false,
          ...basePayload,
        });
        message.success(t('dialog.add_edit_lead_type.create.success.description'));
      } else {
        await LeadTypesApiService.update(existing.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_lead_type.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.refreshTable();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'dialog.add_edit_lead_type.edit.error.description'
            : 'dialog.add_edit_lead_type.create.error.description'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddLeadTypeFormOutput>
        onFinish={submit}
        fields={AddLeadTypeFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-lead-type'
        existingValue={existing}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_lead_type.create.title' : 'dialog.add_edit_lead_type.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditLeadTypeDialog;
