import { App } from 'antd';
import { GroupApiService } from 'core/api';
import { IUserSearchResult } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import SharedUserSearch from 'shared/algolia/user-search';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IGroupDetailAddMemberDialog {
  uid: string;
  currentMembers: string[];
}

const GroupDetailAddMemberDialog = ({ uid, currentMembers }: IGroupDetailAddMemberDialog) => {
  const { t } = useTranslation();
  const [selectedMembers, setSelectedMembers] = useState<IUserSearchResult[]>([]);
  const combinedMembers = [...selectedMembers.map((member) => member.objectID), ...currentMembers];
  const dialog = useDialog();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();

  const userSelected = (user: IUserSearchResult) => {
    setSelectedMembers((prevState) => [...prevState, user]);
  };

  const handleRemoveMember = (member: IUserSearchResult) => {
    setSelectedMembers((prevState) => prevState.filter((m) => member.objectID !== m.objectID));
  };

  const addNewMembers = async () => {
    setLoading(true);
    try {
      await GroupApiService.update(uid, { members: combinedMembers, updated: getActionTimestampFromUser(userData) });
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      message.error(t('dialog.add_group_member.error.description'));
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'addMembers',
      props: {
        loading,
        onClick: () => addNewMembers(),
        appearance: 'primary',
        labelKey: 'common.save',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('groups.group_detail.members.add_member')}
      customContentTemplate={
        <CustomContent
          combinedMembers={combinedMembers}
          selectedMembers={selectedMembers}
          userSelected={userSelected}
          handleRemoveMember={handleRemoveMember}
        />
      }
      customButtons={customButtons}
    />
  );
};

const CustomContent = ({
  combinedMembers,
  selectedMembers,
  userSelected,
  handleRemoveMember,
}: {
  combinedMembers: string[];
  selectedMembers: IUserSearchResult[];
  userSelected: (user: IUserSearchResult) => void;
  handleRemoveMember: (member: IUserSearchResult) => void;
}) => {
  return (
    <div>
      <div className='p-4'>
        <SharedUserSearch
          excludedUsers={combinedMembers}
          onSelect={userSelected}
          additionalFilters={[(user: IUserSearchResult) => !user.isOrgOwner]}
        />
        {selectedMembers.length > 0 && (
          <div className='mt-3 flex flex-wrap space-x-2'>
            {selectedMembers.map((member) => (
              <button
                key={member.objectID}
                onClick={() => handleRemoveMember(member)}
                onKeyDown={(e) => e.key === 'Enter' && handleRemoveMember(member)}
                tabIndex={0}
                className='rounded-full px-3 py-1 bg-gray-200 flex items-center w-fit space-x-1 cursor-pointer hover:bg-gray-300 transition ease-in-out'
              >
                <p className='body-xs'>{member.fullName}</p>
                <X size={14} />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupDetailAddMemberDialog;
