import { ISharedPaginatedTable } from './table.interface';
import { QuerySnapshot, endBefore, getDocs, limit, limitToLast, query, startAfter } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import SharedTable from './table';
import SharedButton from 'shared/button/button';
import { useTranslation } from 'react-i18next';
import { useTablesState } from 'core/providers/table-data-provider';
import { App } from 'antd';

const SharedPaginatedTable = ({
  tableKey,
  collectionRef,
  queryConstraints = [],
  tableConfig,
  queryLimit = 20,
  queryOrder,
  errorMessageKey,
}: ISharedPaginatedTable) => {
  const initialQuery = query(collectionRef, queryOrder, ...queryConstraints);
  const [cursor, setCursor] = useState(0);
  const [onLastPage, setOnLastPage] = useState(false);
  const [q, setQ] = useState(query(initialQuery, limit(queryLimit)));
  const [querySnapshot, setQuerySnapshot] = useState<QuerySnapshot>();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { register } = useTablesState();

  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const snapshot = await getDocs(q);
      setQuerySnapshot(snapshot);
      setLoading(false);
    } catch (error) {
      message.error(t(errorMessageKey));
    }
  }, [errorMessageKey, message, q, t]);

  useEffect(() => {
    register({ key: tableKey, refreshTable: getTableData });
  }, [tableKey, getTableData, register]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    const checkForMorePages = async () => {
      if (!querySnapshot) {
        return;
      }
      const { docs, size, empty } = querySnapshot;
      if (empty) {
        setOnLastPage(true);
        return;
      }
      try {
        const docCheck = await getDocs(query(initialQuery, startAfter(docs[size - 1]), limit(queryLimit)));
        if (docCheck.empty) {
          setOnLastPage(true);
        } else {
          setOnLastPage(false);
        }
      } catch (error) {
        message.error(t(errorMessageKey));
      }
    };
    checkForMorePages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySnapshot]);

  const previousPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs } = querySnapshot;
    setQ(query(initialQuery, endBefore(docs[0]), limitToLast(queryLimit)));
    setCursor((prevState) => prevState - 1);
  };

  const nextPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs, size } = querySnapshot;
    setQ(query(initialQuery, startAfter(docs[size - 1]), limit(queryLimit)));
    setCursor((prevState) => prevState + 1);
  };

  return (
    <>
      <SharedTable
        {...tableConfig}
        rows={
          querySnapshot?.docs.map((doc) => {
            return {
              key: doc.id,
              data: doc.data(),
            };
          }) ?? []
        }
        loading={loading}
      />

      <div className='p-3 border-t space-x-3 flex justify-end'>
        <SharedButton
          labelKey='common.previous'
          appearance='primary'
          disabled={cursor === 0 || loading}
          onClick={previousPage}
        />
        <SharedButton labelKey='common.next' appearance='primary' disabled={onLastPage || loading} onClick={nextPage} />
      </div>
    </>
  );
};

export default SharedPaginatedTable;
