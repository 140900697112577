import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { GroupApiService } from 'core/api';
import { useNavigate } from 'react-router-dom';
import { IGroupDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { App } from 'antd';
const nameFieldKey = 'name';
const descriptionFieldKey = 'description';
interface IAddGroupFormOutput {
  [nameFieldKey]: string;
  [descriptionFieldKey]: string;
}
interface IAddEditGroupDialog {
  groupData?: IGroupDao;
}

const AddEditGroupDialog = ({ groupData }: IAddEditGroupDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const creating = !groupData;
  const AdminAddGroupFormFields: ISharedField[] = [
    {
      fieldKey: nameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
    {
      fieldKey: descriptionFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.description'),
      required: false,
    },
  ];

  const submit = async (data: IAddGroupFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error("This user's organisationUid is missing or invalid");
      }

      const payload = {
        name: data[nameFieldKey],
        description: data[descriptionFieldKey] ?? '',
        organisationUid: userData.organisationUid,
      };

      if (creating) {
        const uid = await GroupApiService.createGroup(payload);
        navigate(`/organisation-settings/groups/${uid}`);
        dialog?.closeDialog();
      } else {
        await GroupApiService.update(groupData.uid, { ...payload, updated: getActionTimestampFromUser(userData) });
        dialog?.closeDialog();
        message.success(t('dialog.add_group.edit.success.description'));
      }
    } catch (err) {
      message.error(t('dialog.add_group.create.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddGroupFormOutput>
        onFinish={submit}
        fields={AdminAddGroupFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        existingValue={
          groupData && {
            [nameFieldKey]: groupData.name,
            [descriptionFieldKey]: groupData.description ?? '',
          }
        }
        name='add-edit-group-dialog'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_group.create.title' : 'dialog.add_group.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditGroupDialog;
