import { FirebaseFunctionName } from 'core/constants/firebase-function-name';
import createService from './factory';
import {
  IOrganisationDao,
  ILeadTypeDao,
  IAccessoryDao,
  IAppointmentTypeDao,
  IAudiometerDao,
  IClinicDao,
  IFormSettingsDao,
  IGroupDao,
  IHearingAidDao,
  IServiceDao,
  IPatientDao,
  IUserDao,
  ICreateUserRequestData,
  ICreateGroupRequestData,
  IUserSearchResult,
  IAlgoliaSearchRequestData,
  IPatientSearchResult,
} from './types';
import { CollectionID } from 'core/constants/collection-id';
import { getDocs, query, where } from 'firebase/firestore';
import { IWorkflowDao } from './types/workflow.interface';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'core/config/firebase';

export const OrganisationsApiService = {
  ...createService<IOrganisationDao>(CollectionID.ORGANISATIONS),
  resetOrganisationSettings: (uid: string) => {
    return callFunction(FirebaseFunctionName.RESET_ORGANISATION_SETTINGS, {
      organisationUid: uid,
    });
  },
};
export const GroupApiService = {
  ...createService<IGroupDao>(CollectionID.GROUPS),
  createGroup: (payload: ICreateGroupRequestData) => {
    return callFunction(FirebaseFunctionName.CREATE_GROUP, payload);
  },
};
export const ClinicApiService = createService<IClinicDao>(CollectionID.CLINICS);
export const HearingAidsApiService = createService<IHearingAidDao>(CollectionID.HEARING_AIDS);
export const AccessoriesApiService = createService<IAccessoryDao>(CollectionID.ACCESSORIES);
export const ServicesApiService = createService<IServiceDao>(CollectionID.SERVICES);
export const LeadTypesApiService = createService<ILeadTypeDao>(CollectionID.LEAD_TYPES);
export const AppointmentTypesApiService = createService<IAppointmentTypeDao>(CollectionID.APPOINTMENT_TYPES);
export const AudiometersApiService = createService<IAudiometerDao>(CollectionID.AUDIOMETERS);
export const PatientApiService = {
  ...createService<IPatientDao>(CollectionID.PATIENTS),
  search: (query: string) => {
    return callFunction<IAlgoliaSearchRequestData, IPatientSearchResult[]>(FirebaseFunctionName.SEARCH_PATIENT, {
      query,
    });
  },
};
export const OrderFormSettingsApiService = createService<IFormSettingsDao>(CollectionID.ORDER_FORM_SETTINGS);
export const PatientFormSettingsApiService = createService<IFormSettingsDao>(CollectionID.PATIENT_FORM_SETTINGS);
export const WorkflowApiService = createService<IWorkflowDao>(CollectionID.PATIENT_WORKFLOWS);

export const UsersApiService = {
  ...createService<IUserDao>(CollectionID.USERS),
  createAdmin: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_ADMIN, payload);
  },
  createOrganisationOwner: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_ORGANISATION_OWNER, payload);
  },
  createOrganisationMember: (payload: ICreateUserRequestData): Promise<string> => {
    return callFunction<ICreateUserRequestData, string>(FirebaseFunctionName.CREATE_MEMBER, payload);
  },
  getOrgOwner: (orgUid: string) => {
    const q = query(
      UsersApiService.collectionRef,
      where('organisation', '==', orgUid),
      where('isOrgOwner', '==', true)
    );
    return getDocs(q.withConverter(UsersApiService.converter<IUserDao>()));
  },
  remove: (user: IUserDao) => {
    return callFunction<IUserDao, void>(FirebaseFunctionName.DELETE_USER, user);
  },
  adminRemove: (user: IUserDao) => {
    return callFunction<IUserDao, void>(FirebaseFunctionName.ADMIN_DELETE_USER, user);
  },
  search: (query: string) => {
    return callFunction<IAlgoliaSearchRequestData, IUserSearchResult[]>(FirebaseFunctionName.SEARCH_USER, { query });
  },
};

const callFunction = async <T, K>(name: FirebaseFunctionName, payload: T) => {
  const fbFunction = httpsCallable<T, K>(functions, name);
  const result = await fbFunction(payload);
  return result.data;
};
