import { ArrowLeftCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageElement } from 'shared/helpers/interface.helpers';

interface ISharedPageHeader {
  title: string;
  actions?: PageElement[];
  subtitle?: string;
  showBack?: boolean;
  backPath?: string;
  backLabel?: string;
}

const SharedPageHeader = ({ title, actions, subtitle, showBack = false, backPath, backLabel }: ISharedPageHeader) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='flex-grow-1 flex-shrink-0 lg:basis-[88px] flex justify-between py-4'>
      <div>
        {showBack && (
          <div
            className='flex items-center text-white-600 mb-2 cursor-pointer text-gray-600'
            onClick={() => (backPath ? navigate(backPath) : navigate(-1))}
          >
            <ArrowLeftCircle size={16} />
            <p className='body-sm ml-1'>{backLabel ?? t('common.back')}</p>
          </div>
        )}
        <p className='text-2xl font-medium'>{title}</p>
        {subtitle && <p className='body-01 text-gray-400'>{subtitle}</p>}
      </div>
      <div className='flex items-center justify-end'>
        {actions?.map((action) => (
          <div key={action.key}>{action.element}</div>
        ))}
      </div>
    </div>
  );
};

export default SharedPageHeader;
