import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useUserState } from './user-provider';
import { Permission } from 'core/constants/permission';
import { useLocation } from 'react-router-dom';
import { User } from 'firebase/auth';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

interface IPermissionsContext {
  userPermissions?: string[];
  isOrgOwner: boolean;
  isSuperAdmin: boolean;
}

export const PermissionsContext = createContext<IPermissionsContext>({
  isOrgOwner: false,
  isSuperAdmin: false,
});

export const usePermissionsState = () => {
  const state = useContext(PermissionsContext);
  return state;
};

export const PermissionsProvider = ({ children }: any) => {
  const { user } = useUserState();
  const [userPermissions, setUserPermissions] = useState<string[]>();
  const [isOrgOwner, setIsOrgOwner] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const location = useLocation();
  const { message } = App.useApp();
  const { t } = useTranslation();

  const processUserPermissions = useCallback(
    async (user: User) => {
      try {
        const idTokenResult = await user.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        const permissions = claims.permissions ? (claims.permissions as string[]) : [];
        setIsSuperAdmin(permissions.includes(Permission.HEARLINK_ADMIN));
        setIsOrgOwner(permissions.includes(Permission.ORGANISATION_OWNER));
        setUserPermissions(permissions);
      } catch (error) {
        message.error(t('misc.permissions.error'));
      }
    },
    [message, t]
  );

  useEffect(() => {
    if (!user) {
      setUserPermissions(undefined);
      setIsOrgOwner(false);
      setIsSuperAdmin(false);
    } else {
      processUserPermissions(user);
    }
  }, [user, location, processUserPermissions]);

  return (
    <PermissionsContext.Provider value={{ userPermissions, isOrgOwner, isSuperAdmin }}>
      {children}
    </PermissionsContext.Provider>
  );
};
