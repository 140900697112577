import clsx from 'clsx';

interface ISharedCard {
  children: any;
  className?: string;
}

const SharedCard = ({ children, className }: ISharedCard) => {
  const classes = clsx('rounded-md bg-white shadow-md overflow-clip', className);
  return <div className={classes}>{children}</div>;
};

export default SharedCard;
