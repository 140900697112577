import React, { ReactElement } from 'react';
import { useDialog } from 'core/providers/dialog-provider';
import SharedButton from 'shared/button/button';
import { ISharedButton } from 'shared/button/button.interface';

interface ISharedDialogBase {
  title: string;
  subtitle?: string;
  textContent?: string;
  customContentTemplate?: ReactElement;
  showButtons?: boolean;
  customButtons?: ISharedDialogButton[];
}

export interface ISharedDialogButton {
  key: string;
  props: ISharedButton;
}

const SharedDialogBase = ({
  title,
  subtitle,
  textContent,
  customContentTemplate,
  showButtons = true,
  customButtons,
}: ISharedDialogBase) => {
  const dialog = useDialog();
  const defaultButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      props: {
        onClick: () => dialog?.closeDialog(),
        labelKey: 'common.close',
        type: 'button',
      },
    },
  ];
  const buttons = customButtons ?? defaultButtons;
  return (
    <div className='rounded-lg bg-white w-full md:w-[600px] lg:w-[700px] flex flex-col'>
      <div className='border-b p-4'>
        <p className='header-md'>{title}</p>
        {subtitle && <p className='body-02 mt-1'>{subtitle}</p>}
      </div>
      {textContent && <p className='body-sm py-6 px-4'>{textContent}</p>}
      {customContentTemplate && <>{customContentTemplate}</>}
      {showButtons && (
        <div className='border-t p-4 flex justify-end space-x-2'>
          {buttons.map((button) => (
            <SharedButton key={button.key} {...button.props} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SharedDialogBase;
