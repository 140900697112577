import { ControlType } from 'core/enums/control-type';
import { Checkbox, ColorPicker, DatePicker, Input, InputNumber, Select } from 'antd';
import { ISharedField } from './shared-fields.interface';
import AddressSearch from './address-search';
import { InputType } from 'core/enums/input-type';
import SharedFileUpload from './file-upload';

export const getField = (field: ISharedField) => {
  switch (field.control) {
    case ControlType.TextField:
      return field.type === InputType.Password ? <Input.Password /> : <Input placeholder={field.placeholder} />;
    case ControlType.TextArea:
      return <Input.TextArea rows={field.rows} />;
    case ControlType.NumberField:
      return <InputNumber min={field.min} max={field.max} />;
    case ControlType.ColorPicker:
      return <ColorPicker showText={field.showText} defaultValue={field.defaultValue} format={'hex'} />;
    case ControlType.Address:
      return <AddressSearch />;
    case ControlType.Select:
      return <Select placeholder={field.placeholder} options={field.options} />;
    case ControlType.File:
      return (
        <SharedFileUpload
          acceptedFileTypes={field.acceptedFileTypes}
          maxCount={field.maxCount}
          maxFileSize={field.maxFileSize}
        />
      );
    case ControlType.DatePicker:
      return <DatePicker format={'DD/MM/YYYY'} />;
    case ControlType.CheckboxGroup:
      return <Checkbox.Group options={field.options} />;
    case ControlType.SelectMultipleTags:
      return <Select mode='tags' />;
    default:
      throw new Error('Control type not found');
  }
};
