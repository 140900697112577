import { Navigate, Route, Routes } from 'react-router-dom';
import OrganisationSettingLayout from './organisation-settings-layout/organisation-settings-layout';
import PermissionGuard from 'core/routing/permission-guard';
import { Permission } from 'core/constants/permission';
import GroupsRouting from 'modules/organisation-settings/groups/groups-routing';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import ClinicsRouting from './clinics/clinics-routing';
import AppointmentTypesRouting from './appointment-types/appointment-types-routing';
import LeadTypesRouting from './lead-types/lead-types-routing';
import FormSettingsRouting from './form-settings/form-settings-routing';
import ProductsAndServicesRouting from './products-and-services/products-and-services-routing';
import UsersRouting from './users/users-routing';
import AudiometersRouting from './audiometers/audiometers-routing';
import AuditLogRouting from './audit-log/audit-log-routing';
import WorkflowRouting from './workflow/workflow-routing';

const OrganisationSettingsRouting = () => {
  return (
    <Routes>
      <Route element={<OrganisationSettingLayout />}>
        <Route
          element={
            <PermissionGuard requiredPermissions={[[Permission.USERS_BROWSE], [Permission.ORGANISATION_OWNER]]} />
          }
        >
          <Route path='users/*' element={<UsersRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.GROUPS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='groups/*' element={<GroupsRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.CLINICS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='clinics/*' element={<ClinicsRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.APPOINTMENT_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='appointment-types/*' element={<AppointmentTypesRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.LEAD_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='lead-types/*' element={<LeadTypesRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.FORM_SETTINGS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='form-settings/*' element={<FormSettingsRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='products-and-services/*' element={<ProductsAndServicesRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.AUDIOMETERS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='audiometers/*' element={<AudiometersRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.AUDIT_LOG_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='audit-log/*' element={<AuditLogRouting />} />
        </Route>

        <Route
          element={
            <PermissionGuard
              requiredPermissions={[[Permission.WORKFLOW_BROWSE], [Permission.ORGANISATION_OWNER]]}
              navList={OrganisationSettingsNavbarOptions}
            />
          }
        >
          <Route path='workflow/*' element={<WorkflowRouting />} />
        </Route>
      </Route>
      <Route path='' element={<Navigate replace to='users' />} />
      <Route path='*' element={<Navigate replace to='users' />} />
    </Routes>
  );
};

export default OrganisationSettingsRouting;
