import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { IUpdateDataPayload } from './types';

const createService = <T>(collectionId: CollectionID) => {
  const collectionRef = collection(firestore, collectionId);

  const converter = <T>() => ({
    toFirestore: (dao: WithFieldValue<T>) => dao,
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => snapshot.data() as T,
  });

  const get = (uid: string) => {
    const docRef = doc(firestore, collectionId, uid).withConverter(converter<T>());
    return getDoc(docRef);
  };

  const getAll = (constraints: QueryConstraint[] = []) => {
    const q = query(collectionRef, ...constraints);
    return getDocs(q.withConverter(converter<T>()));
  };

  const set = (dao: T & { uid: string }) => {
    return setDoc(doc(firestore, collectionId, dao.uid).withConverter(converter<T>()), dao);
  };

  const update = async (uid: string, update: IUpdateDataPayload<T>) => {
    const docRef = doc(firestore, collectionId, uid).withConverter(converter<T>());
    return updateDoc(docRef, update);
  };

  const onDocSnapshot = (
    uid: string,
    resultHandler: (snap: DocumentSnapshot<T, DocumentData>) => void,
    errorHandler: (error: FirestoreError) => void
  ) => {
    const docRef = doc(firestore, collectionId, uid).withConverter(converter<T>());
    return onSnapshot(
      docRef,
      (snapShot: DocumentSnapshot<T>) => {
        resultHandler(snapShot);
      },
      (error: FirestoreError) => {
        errorHandler(error);
      }
    );
  };

  const onCollectionSnapshot = (
    resultHandler: (snap: QuerySnapshot<T, DocumentData>) => void,
    errorHandler: (error: FirestoreError) => void,
    constraints: QueryConstraint[] = []
  ) => {
    const q = query(collectionRef, ...constraints).withConverter(converter<T>());
    return onSnapshot(
      q,
      (snapShot) => {
        resultHandler(snapShot);
      },
      (error) => {
        errorHandler(error);
      }
    );
  };

  return {
    get,
    getAll,
    set,
    update,
    onDocSnapshot,
    onCollectionSnapshot,
    converter,
    collectionRef,
  };
};

export default createService;
