import { Permission } from 'core/constants/permission';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import { Briefcase, Icon, Settings, Smile, Users } from 'react-feather';

export interface INavbarOption {
  NavIcon?: Icon;
  labelKey: string;
  route: string;
  requiredPermissions: string[][];
}

export const NavbarOptions: INavbarOption[] = [
  {
    NavIcon: Briefcase,
    labelKey: 'navigation.organisations',
    route: 'admin/organisations',
    requiredPermissions: [[Permission.HEARLINK_ADMIN]],
  },
  {
    NavIcon: Users,
    labelKey: 'navigation.users',
    route: 'admin/users',
    requiredPermissions: [[Permission.HEARLINK_ADMIN]],
  },
  {
    NavIcon: Smile,
    labelKey: 'navigation.patients',
    route: 'patients',
    requiredPermissions: [[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    NavIcon: Settings,
    labelKey: 'navigation.organisation_settings',
    route: 'organisation-settings',
    requiredPermissions: [
      [Permission.ORGANISATION_OWNER],
      ...OrganisationSettingsNavbarOptions.flatMap((option) => option.requiredPermissions),
    ],
  },
];
