import { Outlet } from 'react-router-dom';
import OrganisationSettingsMenu from './organisation-settings-menu';

const OrganisationSettingLayout = () => {
  return (
    <div className='h-full flex flex-col md:flex-row relative overflow-hidden'>
      <OrganisationSettingsMenu />
      <div className='overflow-y-auto px-4 pb-4 w-full'>
        <Outlet />
      </div>
    </div>
  );
};

export default OrganisationSettingLayout;
