import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { IAppointmentTypeDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { AppointmentTypesApiService } from 'core/api';
import { v4 as uuidv4 } from 'uuid';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ColorFactory } from 'antd/es/color-picker/color';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';

const nameFieldKey = 'name';
const colourFieldKey = 'colour';
const durationFieldKey = 'duration';
interface IAddAppointmentTypeFormOutput {
  [nameFieldKey]: string;
  [colourFieldKey]: ColorFactory;
  [durationFieldKey]: number;
}
interface IAddEditAppointmentTypeDialog {
  appointmentTypeData?: IAppointmentTypeDao;
  tableKey: string;
}
const AddEditAppointmentTypeDialog = ({ appointmentTypeData, tableKey }: IAddEditAppointmentTypeDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const creating = !appointmentTypeData;
  const AddAppointmentTypeFormFields: ISharedField[] = [
    {
      fieldKey: nameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
    {
      fieldKey: colourFieldKey,
      control: ControlType.ColorPicker,
      label: t('dialog.add_edit_appointment_type.form.colour.title'),
      required: true,
    },
    {
      fieldKey: durationFieldKey,
      control: ControlType.NumberField,
      label: t('dialog.add_edit_appointment_type.form.duration.title'),
      required: true,
      min: 1,
    },
  ];
  const table = useTable(tableKey);

  const submit = async (data: IAddAppointmentTypeFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error("This user's organisationUid is missing or invalid");
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        updated: userTimestamp,
        name: data[nameFieldKey],
        colour: data[colourFieldKey].toHexString(),
        duration: data[durationFieldKey],
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await AppointmentTypesApiService.set({
          uid: uuidv4(),
          created: userTimestamp,
          deleted: false,
          ...basePayload,
        });
        message.success(t('dialog.add_edit_appointment_type.create.success.description'));
      } else {
        await AppointmentTypesApiService.update(appointmentTypeData.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_appointment_type.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.refreshTable();
    } catch (err) {
      message.error(t('dialog.add_edit_appointment_type.create.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddAppointmentTypeFormOutput>
        onFinish={submit}
        fields={AddAppointmentTypeFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-appointment-type-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating ? 'dialog.add_edit_appointment_type.create.title' : 'dialog.add_edit_appointment_type.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditAppointmentTypeDialog;
