import { App } from 'antd';
import { DialogProvider } from 'core/providers/dialog-provider';
import { PermissionsProvider } from 'core/providers/permissions-provider';
import { TableDataProvider } from 'core/providers/table-data-provider';
import { ThemeProvider } from 'core/providers/theme-provider';
import { UserProvider } from 'core/providers/user-provider';
import AppRouting from 'core/routing/app-routing';

function HearLinkApp() {
  return (
    <App>
      <TableDataProvider>
        <UserProvider>
          <ThemeProvider>
            <DialogProvider>
              <PermissionsProvider>
                <AppRouting />
              </PermissionsProvider>
            </DialogProvider>
          </ThemeProvider>
        </UserProvider>
      </TableDataProvider>
    </App>
  );
}

export default HearLinkApp;
