import { Navigate, Route, Routes } from 'react-router-dom';
import PatientDashboard from './patient-dashboard/patient-dashboard';
import PatientOverview from './patient-overview/patient-overview';

const PatientsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<PatientDashboard />} />
      <Route path=':uid' element={<PatientOverview />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default PatientsRouting;
