import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';

const FormSettingsList = () => {
  const { t } = useTranslation();

  const settingsOptions = [
    {
      key: 'patient',
      label: t('form_settings.patient_form'),
      route: 'patient-form',
    },
    {
      key: 'order',
      label: t('form_settings.order_form'),
      route: 'order-form',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.form_settings')} />
      <SharedCard className='mb-4'>
        {settingsOptions.map((option) => (
          <div key={option.label} className='flex justify-between items-center border-b p-3 body-sm'>
            <p>{option.label}</p>
            <Link to={option.route}>
              <SharedButton type='button' appearance='link' labelKey='common.edit' primaryOverride />
            </Link>
          </div>
        ))}
      </SharedCard>
    </>
  );
};

export default FormSettingsList;
