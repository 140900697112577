import { App, Checkbox } from 'antd';
import { GroupApiService } from 'core/api';
import { Permission, groupPermissionSections } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

interface IGroupDetailPermissions {
  uid: string;
  currentPermissions: string[];
}

const GroupDetailPermissions = ({ uid, currentPermissions }: IGroupDetailPermissions) => {
  const { t } = useTranslation();
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();

  useEffect(() => {
    setSelectedPermissions(currentPermissions);
  }, [currentPermissions, editing]);

  const checkboxChanged = (key: string, value: boolean) => {
    if (value) {
      setSelectedPermissions((prevState) => [...prevState, key]);
    } else {
      setSelectedPermissions((prevState) => prevState.filter((permission) => permission !== key));
    }
  };

  const getViewOnlyPermissions = (permissions: string[]) => {
    const activePermissions = permissions.filter((permission) => currentPermissions.includes(permission));
    if (activePermissions.length === 0) {
      return <p className='body-sm opacity-40'>{t('groups.group_detail.permissions.section.no_permissions')}</p>;
    }

    return activePermissions.map((permission) => (
      <p key={permission} className='body-sm'>
        {t(`permissions.${permission.replace(':', '_')}.label`)}
      </p>
    ));
  };

  const savePermissions = async () => {
    setLoading(true);
    try {
      await GroupApiService.update(uid, {
        permissions: selectedPermissions,
        updated: getActionTimestampFromUser(userData),
      });
      setLoading(false);
      setEditing(false);
      message.success(t('groups.group_detail.permissions.success.description'));
    } catch (error) {
      message.error(t('groups.group_detail.permissions.error.description'));
      setLoading(false);
    }
  };

  return (
    <SharedCard className='h-fit'>
      <div className='px-4 py-3 border-b flex justify-between items-center h-[54px]'>
        <p className='header-md'>{t('groups.group_detail.permissions.title')}</p>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.GROUPS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <div className='flex items-center space-x-4'>
            <SharedButton
              onClick={() => setEditing(!editing)}
              appearance='link'
              labelKey={editing ? 'common.cancel' : 'common.edit'}
              disabled={loading}
              primaryOverride
            />
            {editing && (
              <SharedButton loading={loading} onClick={savePermissions} appearance='primary' labelKey={'common.save'} />
            )}
          </div>
        </SharedElementPermissionGuard>
      </div>
      <div className='p-4'>
        {groupPermissionSections.map((section) => (
          <div className='flex items mt-4 first:mt-0 border-b pb-4 last:border-0 last:pb-0' key={section.key}>
            <p className='w-[35%] label-sm'>{t(section.labelTranslationKey)}</p>
            <div className='w-[65%] flex flex-col space-y-2'>
              {editing
                ? section.permissions.map((permission) => (
                    <Checkbox
                      key={permission}
                      checked={selectedPermissions.includes(permission)}
                      disabled={!editing || loading}
                      onChange={(event) => checkboxChanged(permission, event.target.checked)}
                    >
                      {t(`permissions.${permission.replace(':', '_')}.label`)}
                    </Checkbox>
                  ))
                : getViewOnlyPermissions(section.permissions)}
            </div>
          </div>
        ))}
      </div>
    </SharedCard>
  );
};

export default GroupDetailPermissions;
