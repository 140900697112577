import { App } from 'antd';
import { IClinicDao } from 'core/api/types';
import { ClinicApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';

interface IDeleteClinic {
  clinic: IClinicDao;
  tableKey: string;
}

const DeleteClinic = ({ clinic, tableKey }: IDeleteClinic) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useTable(tableKey);
  const { userData } = useUserState();

  const deleteUser = async () => {
    setLoading(true);
    try {
      const userTimestamp = getActionTimestampFromUser(userData);
      await ClinicApiService.update(clinic.uid, { deleted: true, updated: userTimestamp });
      dialog?.closeDialog();
      message.success(t('dialog.delete_clinic.success.description'));
      table?.refreshTable();
    } catch (error) {
      message.error(t('dialog.delete_clinic.error.description'));
      setLoading(false);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteUser',
      props: {
        loading,
        onClick: () => deleteUser(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.delete_clinic.title', { clinicName: clinic.name })}
      textContent={t('dialog.delete_clinic.content')}
      customButtons={customButtons}
    />
  );
};

export default DeleteClinic;
