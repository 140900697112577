import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentsList from './appointments-list';

const AppointmentsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AppointmentsList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AppointmentsRouting;
