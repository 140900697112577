export const ContactMethod = {
  EMAIL: 'email',
  PHONE: 'phone',
  SMS: 'sms',
};

export const ContactMethodData = {
  [ContactMethod.EMAIL]: {
    value: ContactMethod.EMAIL,
    translationLabelKey: 'contact_method.email',
  },
  [ContactMethod.PHONE]: {
    value: ContactMethod.PHONE,
    translationLabelKey: 'contact_method.phone',
  },
  [ContactMethod.SMS]: {
    value: ContactMethod.SMS,
    translationLabelKey: 'contact_method.sms',
  },
};

export const ContactMethodOptions = [ContactMethod.EMAIL, ContactMethod.PHONE, ContactMethod.SMS];
