import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { IServiceDao } from 'core/api/types';
import { ServicesApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';

interface IAddServicesFormOutput extends IServiceDao {}

interface IAddEditServicesDialog {
  existing?: IServiceDao;
  tableKey: string;
}
const AddEditServicesDialog = ({ existing, tableKey }: IAddEditServicesDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;
  const table = useTable(tableKey);

  const AddServicesFormFields: ISharedField[] = [
    {
      fieldKey: 'serviceName',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('products_and_services.services.add_edit_services.service_name'),
      required: true,
    },
    {
      fieldKey: 'unitCost',
      control: ControlType.NumberField,
      label: t('products_and_services.services.add_edit_services.unit_cost'),
      required: true,
    },
    {
      fieldKey: 'rrp',
      control: ControlType.NumberField,
      label: t('products_and_services.services.add_edit_services.rrp'),
      required: true,
    },
  ];

  const submit = async (data: IAddServicesFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error("This user's organisationUid is missing or invalid");
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        ...data,
        updated: userTimestamp,
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await ServicesApiService.set({
          ...basePayload,
          uid: uuidv4(),
          created: userTimestamp,
          deleted: false,
        });
        message.success(t('dialog.add_edit_services.create.success.description'));
      } else {
        await ServicesApiService.update(existing.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_services.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.refreshTable();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'dialog.add_edit_services.edit.error.description'
            : 'dialog.add_edit_services.create.error.description'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddServicesFormOutput>
        className='overflow-y-auto p-4'
        onFinish={submit}
        fields={AddServicesFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-services'
        existingValue={existing}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_services.create.title' : 'dialog.add_edit_services.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditServicesDialog;
