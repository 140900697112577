import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import { useDialog } from 'core/providers/dialog-provider';
import AdminAddEditOrganisationDialog from './admin-add-edit-organisation-dialog';
import { useSelector } from 'react-redux';
import { OrganisationsSlice } from './admin-organisations-slice';
import { useTranslation } from 'react-i18next';
import { IOrganisationDao } from 'core/api/types';
import { OrganisationStatus, OrganisationStatusData } from 'core/constants/organisation-status';
import AdminActivateOrganisationDialog from './admin-activate-organisation-dialog';
import AdminDeactivateOrganisationDialog from './admin-deactivate-organisation-dialog';
import { OrganisationsApiService } from 'core/api';

const OrganisationsTableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'admin.organisations_list.table.header.name',
    key: 'name',
  },
  {
    labelKey: 'admin.organisations_list.table.header.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const AdminOrganisationsList = () => {
  const { data, status } = useSelector(OrganisationsSlice.selectState);
  const dialog = useDialog();
  const { t } = useTranslation();

  const statusTemplate = (org: IOrganisationDao) => {
    const status = OrganisationStatusData[org.status];
    return (
      <div className='flex items-center space-x-2'>
        <div className='rounded-full h-2 w-2' style={{ backgroundColor: status.color }} />
        <p>{t(status.translationLabelKey)}</p>
      </div>
    );
  };

  const actionTemplate = (org: IOrganisationDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedButton
          onClick={() => dialog?.openDialog(<AdminAddEditOrganisationDialog organisation={org} />)}
          type='button'
          appearance='link'
          labelKey='common.edit'
          primaryOverride
        />
        <SharedButton
          onClick={() =>
            dialog?.openDialog(
              org.status === OrganisationStatus.ACTIVE ? (
                <AdminDeactivateOrganisationDialog organisation={org} />
              ) : (
                <AdminActivateOrganisationDialog organisation={org} />
              )
            )
          }
          type='button'
          appearance='link'
          danger={org.status === OrganisationStatus.ACTIVE}
          primaryOverride={org.status !== OrganisationStatus.ACTIVE}
          labelKey={org.status === OrganisationStatus.ACTIVE ? 'common.deactivate' : 'common.activate'}
        />
        {process.env.NODE_ENV === 'development' && (
          <SharedButton
            onClick={() => OrganisationsApiService.resetOrganisationSettings(org.uid)}
            type='button'
            appearance='link'
            labelKey='admin.organisations_list.table.action.reset_settings'
            danger
          />
        )}
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: statusTemplate,
      id: 'status',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <SharedPageHeader
        title={t('admin.organisations_list.header.title')}
        actions={[
          {
            element: (
              <SharedButton
                labelKey='admin.organisations_list.header.add_organisation'
                appearance='primary'
                onClick={() => dialog?.openDialog(<AdminAddEditOrganisationDialog />)}
              />
            ),
            key: 'addOrg',
          },
        ]}
      />
      <SharedCard className='mb-4'>
        <SharedTable
          loading={status !== 'success'}
          rows={data.map((org) => ({
            key: org.uid,
            data: org,
          }))}
          columns={OrganisationsTableColumns}
          contentTemplates={contentTemplates}
        />
      </SharedCard>
    </>
  );
};

export default AdminOrganisationsList;
