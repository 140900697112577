import { createContext, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  DocumentSnapshot,
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import {
  PatientFormSettingsApiService,
  OrderFormSettingsApiService,
  LeadTypesApiService,
  WorkflowApiService,
} from 'core/api';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useUserState } from './user-provider';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface IOrganisationFirestoreCacheSubscription {
  key: string;
  type: 'document' | 'collection';
  firestoreAction: (...args: any[]) => Unsubscribe;
  actionArgs?: any[];
  queryConstraints?: QueryConstraint[];
  reducer: ActionCreatorWithPayload<any>;
  dataManipulator?: (data: any) => any;
  condition?: boolean;
}

export const OrganisationFirestoreCacheContext = createContext({});

export const OrganisationFirestoreCacheProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const { userData } = useUserState();

  const reformatUpdatedTimestamp = (data: any) => {
    const { updated, ...rest } = data;
    return { ...rest, updatedAtSeconds: updated.at.seconds, updatedBy: updated.by };
  };

  const reformatTimestamps = (data: any) => {
    const { updated, created, ...rest } = data;
    return {
      ...rest,
      updatedAtSeconds: updated.at.seconds,
      updatedBy: updated.by,
      createdAtSeconds: created.at.seconds,
      createdBy: created.by,
    };
  };

  const subscriptions: IOrganisationFirestoreCacheSubscription[] = useMemo(
    () => [
      {
        key: 'patientFormSettings',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: PatientFormSettingsApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updatePatientFormSettings,
        dataManipulator: reformatUpdatedTimestamp,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'orderFormSettings',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: OrderFormSettingsApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updateOrderFormSettings,
        dataManipulator: reformatUpdatedTimestamp,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'leadTypes',
        type: 'collection',
        queryConstraints: [where('organisationUid', '==', userData?.organisationUid)],
        firestoreAction: LeadTypesApiService.onCollectionSnapshot,
        reducer: OrganisationSettingsSlice.updateLeadTypes,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
      {
        key: 'patientWorkflow',
        type: 'document',
        actionArgs: [userData?.organisationUid],
        firestoreAction: WorkflowApiService.onDocSnapshot,
        reducer: OrganisationSettingsSlice.updatePatientWorkflow,
        dataManipulator: reformatTimestamps,
        condition: userData?.organisationUid !== undefined,
      },
    ],
    [userData?.organisationUid]
  );

  const handleCollectionSubscription = useCallback(
    (subscription: IOrganisationFirestoreCacheSubscription) => {
      dispatch(subscription.reducer({ data: [], status: 'loading' }));
      return subscription.firestoreAction(
        (snap: QuerySnapshot) => {
          const data =
            snap.docs.map((doc) =>
              subscription.dataManipulator ? subscription.dataManipulator(doc.data()) : doc.data()
            ) ?? [];
          dispatch(subscription.reducer({ data, status: 'success' }));
        },
        (_: FirestoreError) => {
          dispatch(subscription.reducer({ data: [], status: 'error' }));
        },
        subscription.queryConstraints
      );
    },
    [dispatch]
  );

  const handleDocumentSubscription = useCallback(
    (subscription: IOrganisationFirestoreCacheSubscription) => {
      dispatch(subscription.reducer({ data: undefined, status: 'loading' }));
      const args = subscription.actionArgs ?? [];
      return subscription.firestoreAction(
        ...args,
        (snap: DocumentSnapshot) => {
          if (!snap.exists) {
            dispatch(subscription.reducer({ data: undefined, status: 'error' }));
          } else {
            const data = subscription.dataManipulator ? subscription.dataManipulator(snap.data()) : snap.data();
            dispatch(subscription.reducer({ data, status: 'success' }));
          }
        },
        (_: FirestoreError) => {
          dispatch(subscription.reducer({ data: undefined, status: 'error' }));
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const unsubscribeFunctions = subscriptions.map((subscription) => {
      if (!subscription.condition) {
        return () => {};
      }

      switch (subscription.type) {
        case 'document':
          return handleDocumentSubscription(subscription);
        case 'collection':
          return handleCollectionSubscription(subscription);
        default:
          throw new Error('Invalid subscription type');
      }
    });

    return () => {
      dispatch(OrganisationSettingsSlice.reset());
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
  }, [dispatch, handleCollectionSubscription, handleDocumentSubscription, subscriptions]);

  return <OrganisationFirestoreCacheContext.Provider value={{}}>{children}</OrganisationFirestoreCacheContext.Provider>;
};
