import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditPatientDialog from './add-edit-patient-dialog';

const PatientDashboard = () => {
  const { t } = useTranslation();
  const dialog = useDialog();

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENTS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='patients.patients_dashboard.header.add_patient'
            onClick={() => dialog?.openDialog(<AddEditPatientDialog />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addPatient',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.patients')} actions={headerActions} />
    </>
  );
};

export default PatientDashboard;
