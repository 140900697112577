import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { App } from 'antd';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { OrderFormSettingsApiService, PatientFormSettingsApiService } from 'core/api';
import { CollectionID } from 'core/constants/collection-id';

interface IFormSettingsDeleteCustomField {
  collectionID: string;
  fieldKey: string;
}

const FormSettingsDeleteCustomField = ({ collectionID, fieldKey }: IFormSettingsDeleteCustomField) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { t } = useTranslation();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const patientFormSettings = useSelector(OrganisationSettingsSlice.selectPatientFormSettings);

  const deleteCustomField = async () => {
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation UID not found');
      }
      setLoading(true);

      if (collectionID === CollectionID.PATIENT_FORM_SETTINGS) {
        await PatientFormSettingsApiService.update(userData.organisationUid, {
          customFields: patientFormSettings?.data?.customFields
            .filter((field) => !field.deleted)
            .map((field) => ({ ...field, deleted: field.key === fieldKey })),
          updated: getActionTimestampFromUser(userData),
        });
      }

      if (collectionID === CollectionID.ORDER_FORM_SETTINGS) {
        await OrderFormSettingsApiService.update(userData.organisationUid, {
          customFields: patientFormSettings?.data?.customFields
            .filter((field) => !field.deleted)
            .map((field) => ({ ...field, deleted: field.key === fieldKey })),
          updated: getActionTimestampFromUser(userData),
        });
      }

      message.success(t('form_settings.custom_field.delete.success'));
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      message.error(t('form_settings.custom_field.delete.error'));
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteUser',
      props: {
        loading,
        onClick: () => deleteCustomField(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('form_settings.custom_field.delete.title')}
      textContent={t('form_settings.custom_field.delete.content')}
      customButtons={customButtons}
    />
  );
};

export default FormSettingsDeleteCustomField;
