import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { collection, orderBy, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditAppointmentTypeDialog from './add-edit-appointment-type-dialog';
import { IAppointmentTypeDao } from 'core/api/types';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.name',
    key: 'name',
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.colour',
    key: 'colour',
    contentTemplateId: 'colour',
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.duration',
    key: 'duration',
  },
];

const AppointmentTypesList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'appointment_types.appointment_types_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='appointment_types.appointment_types_list.header.add_appointment_type'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditAppointmentTypeDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAppointmentType',
    },
  ];

  const appTypeColourTemplate = (appointmentType: IAppointmentTypeDao) => {
    return (
      <div className='flex space-x-2 items-center'>
        <div className='h-4 w-4 rounded-full' style={{ backgroundColor: appointmentType.colour }} />
        <p>{appointmentType.colour}</p>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: appTypeColourTemplate,
      id: 'colour',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.appointment_types')} actions={headerActions} />
      <SharedCard className='mb-4'>
        {userData && (
          <SharedPaginatedTable
            collectionRef={collection(firestore, CollectionID.APPOINTMENT_TYPES)}
            queryConstraints={[
              where('organisationUid', '==', userData?.organisationUid),
              where('deleted', '==', false),
            ]}
            queryOrder={orderBy('updated.at', 'desc')}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='appointment_types.appointment_types_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AppointmentTypesList;
