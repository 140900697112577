import { App } from 'antd';
import { ILeadTypeDao } from 'core/api/types';
import { LeadTypesApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IDeleteLeadTypeDialog {
  leadType: ILeadTypeDao;
  tableKey: string;
}

const DeleteLeadTypeDialog = ({ leadType, tableKey }: IDeleteLeadTypeDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useTable(tableKey);
  const { userData } = useUserState();

  const deleteLeadType = async () => {
    setLoading(true);
    try {
      const userTimestamp = getActionTimestampFromUser(userData);
      await LeadTypesApiService.update(leadType.uid, { updated: userTimestamp, deleted: true });
      dialog?.closeDialog();
      message.success(t('dialog.delete_lead_type.success.description'));
      table?.refreshTable();
    } catch (error) {
      message.error(t('dialog.delete_lead_type.error.description'));
      setLoading(false);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteUser',
      props: {
        loading,
        onClick: () => deleteLeadType(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.delete_lead_type.title', { leadTypeName: leadType.name })}
      textContent={t('dialog.delete_lead_type.content')}
      customButtons={customButtons}
    />
  );
};

export default DeleteLeadTypeDialog;
