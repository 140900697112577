import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { collection, where, orderBy } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditClinicDialog from './add-edit-clinic-dialog';
import { IClinicDao } from 'core/api/types';
import DeleteClinic from './delete-clinic-dialog';

const ClinicTableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'clinics.clinics_list.table.header.name',
    key: 'name',
  },
  {
    labelKey: 'clinics.clinics_list.table.header.address',
    key: 'address',
    contentTemplateId: 'address',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const ClinicsList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'clinics.clinics_list.table';

  const addressTemplate = (clinic: IClinicDao) => {
    return <p>{clinic.address.formattedAddress}</p>;
  };

  const actionTemplate = (clinic: IClinicDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.CLINICS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditClinicDialog clinic={clinic} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.CLINICS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<DeleteClinic clinic={clinic} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.CLINICS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='clinics.clinics_list.header.add_clinic'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditClinicDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addClinic',
    },
  ];

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: addressTemplate,
      id: 'address',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.clinics')} actions={headerActions} />
      <SharedCard className='mb-4'>
        {userData && (
          <SharedPaginatedTable
            collectionRef={collection(firestore, CollectionID.CLINICS)}
            queryConstraints={[where('organisationUid', '==', userData?.organisationUid)]}
            queryOrder={orderBy('updated.at', 'desc')}
            tableConfig={{
              contentTemplates: contentTemplates,
              columns: ClinicTableColumns,
            }}
            errorMessageKey='clinics.clinics_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default ClinicsList;
