import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useMemo, useState } from 'react';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { OrganisationsApiService } from 'core/api';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { OrganisationStatus } from 'core/constants/organisation-status';
import { IOrganisationDao } from 'core/api/types';
import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FileType } from 'shared/fields/file-upload';
import { Color } from 'antd/es/color-picker';
import { ColorFactory } from 'antd/es/color-picker/color';

const orgNameK = 'name';
const logoWhiteK = 'logoWhite';
const primaryBgK = 'primaryBg';
const primaryTextK = 'primaryText';

interface IAdminAddEditOrganisationFormOutput {
  [key: string]: string | Color | undefined | FileType[];
  [orgNameK]: string;
  [logoWhiteK]: FileType[];
  [primaryBgK]: Color;
  [primaryTextK]: Color;
}

interface IAdminAddEditOrganisationDialog {
  organisation?: IOrganisationDao;
}

const AdminAddEditOrganisationDialog = ({ organisation }: IAdminAddEditOrganisationDialog) => {
  const [submitting, setSubmitting] = useState(false);
  const creating = !organisation;
  const dialog = useDialog();
  const { t } = useTranslation();
  const { userData } = useUserState();
  const [logoDataUrl, setLogoDataUrl] = useState<string | ArrayBuffer | null>();
  const reader = useMemo(() => {
    return new FileReader();
  }, []);
  const [form] = Form.useForm();
  const logoFiles = useWatch(logoWhiteK, form);
  const selectedBackgroundColor = useWatch<Color | undefined>(primaryBgK, form);
  const selectedTextColor = useWatch<Color | undefined>(primaryTextK, form);
  const { message } = App.useApp();

  useEffect(() => {
    const getLogo = async (organisation: IOrganisationDao) => {
      try {
        const whiteLogo = await getDownloadURL(ref(storage, organisation.themeData.logoWhitePath));
        setLogoDataUrl(whiteLogo);
      } catch (error) {
        message.error(t('admin.add_edit_organisation.logo.fetch_error'));
      }
    };
    if (organisation) {
      getLogo(organisation);
    }
  }, [message, organisation, t]);

  useEffect(() => {
    if (logoFiles) {
      if (logoFiles.length === 0) {
        setLogoDataUrl(undefined);
      } else {
        reader.onloadend = () => {
          setLogoDataUrl(reader.result);
        };
        reader.readAsDataURL(logoFiles[0]);
      }
    }
  }, [logoFiles, reader]);

  const fields: ISharedField[] = [
    {
      fieldKey: orgNameK,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('admin.add_edit_organisation.name'),
      required: true,
    },
    {
      fieldKey: logoWhiteK,
      control: ControlType.File,
      label: t('admin.add_edit_organisation.logo_white'),
      required: creating,
      acceptedFileTypes: ['image/png'],
      maxCount: 1,
      maxFileSize: 2000000,
    },
    {
      fieldKey: primaryBgK,
      control: ControlType.ColorPicker,
      label: t('admin.add_edit_organisation.background_colour'),
      required: true,
    },
    {
      fieldKey: primaryTextK,
      control: ControlType.ColorPicker,
      label: t('admin.add_edit_organisation.text_colour'),
      required: true,
    },
  ];

  const addOrganisation = async (data: IAdminAddEditOrganisationFormOutput) => {
    setSubmitting(true);
    try {
      const uid = uuidv4();
      const logoPath = `logos/${uid}/logoWhite.png`;
      await uploadString(ref(storage, logoPath), logoDataUrl!.toString(), 'data_url');
      const userTimestamp = getActionTimestampFromUser(userData);
      await OrganisationsApiService.set({
        uid,
        created: userTimestamp,
        updated: userTimestamp,
        name: data[orgNameK],
        themeData: {
          [primaryBgK]: data[primaryBgK]?.toHexString(),
          [primaryTextK]: data[primaryTextK]?.toHexString(),
          logoWhitePath: logoPath,
        },
        features: [],
        status: OrganisationStatus.ACTIVE,
      });
      dialog?.closeDialog();
      message.success(t('admin.add_edit_organisation.create.success.description'));
    } catch (error) {
      message.error(t('admin.add_edit_organisation.create.error.description'));
      setSubmitting(false);
    }
  };

  const editOrganisation = async (data: IAdminAddEditOrganisationFormOutput) => {
    setSubmitting(true);
    try {
      if (!organisation) {
        throw new Error(t('admin.add_edit_organisation.edit.error.description'));
      }
      const logoPath = `logos/${organisation.uid}/logoWhite.png`;
      if (data[logoWhiteK]) {
        await uploadString(ref(storage, logoPath), logoDataUrl!.toString(), 'data_url');
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      await OrganisationsApiService.update(organisation.uid, {
        name: data[orgNameK],
        themeData: {
          [primaryBgK]: data[primaryBgK]?.toHexString(),
          [primaryTextK]: data[primaryTextK]?.toHexString(),
          logoWhitePath: logoPath,
        },
        updated: userTimestamp,
      });
      dialog?.closeDialog();
      message.success(t('admin.add_edit_organisation.edit.success.description'));
    } catch (error) {
      message.error(t('admin.add_edit_organisation.edit.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto p-4'>
        <div
          style={{ backgroundColor: selectedBackgroundColor?.toHexString() ?? 'white' }}
          className='h-[80px] border mb-4 p-4 flex justify-between items-center relative'
        >
          {logoDataUrl && <img src={logoDataUrl.toString()} alt='logo' className='max-h-[70%]' />}
          <p className='body-sm' style={{ color: selectedTextColor?.toHexString() ?? '#d1d5db' }}>
            Text preview
          </p>
        </div>
        <SharedForm
          formInstance={form}
          name='add-edit-organisation'
          fields={fields}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          onFinish={creating ? addOrganisation : editOrganisation}
          className='p-0'
          submitting={submitting}
          existingValue={
            organisation && {
              [orgNameK]: organisation.name,
              [primaryBgK]: new ColorFactory(organisation.themeData.primaryBg),
              [primaryTextK]: new ColorFactory(organisation.themeData.primaryText),
            }
          }
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'admin.add_edit_organisation.title.creating' : 'admin.add_edit_organisation.title.editing')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AdminAddEditOrganisationDialog;
