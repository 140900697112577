import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import SharedForm from 'shared/form/shared-form';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { App } from 'antd';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { Dayjs } from 'dayjs';
import { IPatientDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { PatientApiService } from 'core/api';
import { TitleData, TitleOptions } from 'core/constants/title';
import { GenderData, GenderOptions } from 'core/constants/gender';
import { MaritalStatusData, MaritalStatusOptions } from 'core/constants/marital-status';
import { ContactMethodData, ContactMethodOptions } from 'core/constants/contact-method';
import { patientFormDefaultFields } from 'core/constants/organisation-forms';

interface IAddEditPatientFormOutput {
  [key: string]: string | IAddressDao | Dayjs | string[] | undefined;
  referral: string;
  title: string;
  fullName: string;
  address?: IAddressDao;
  phoneNumber: string;
  secondaryPhoneNumber?: string;
  emailAddress?: string;
  dob?: Dayjs;
  gender?: string;
  maritalStatus?: string;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  gpDetails?: string;
  contactPreference?: string;
  contactPermissions?: string[];
}

const AddEditPatientDialog = () => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const patientFormSettings = useSelector(OrganisationSettingsSlice.selectPatientFormSettings);
  const { message } = App.useApp();
  const leadTypeState = useSelector(OrganisationSettingsSlice.selectLeadTypes);

  if (
    !leadTypeState ||
    leadTypeState.status !== 'success' ||
    !patientFormSettings ||
    !patientFormSettings.data ||
    patientFormSettings.status !== 'success'
  ) {
    dialog?.closeDialog();
    message.error(t('patients.add_edit_patient.patient_form_settings_error'));
  }
  const fieldSettings = patientFormSettings!.data!;
  const leadTypes = leadTypeState!.data;

  const configurableDefaultFormFields: ISharedField[] = patientFormDefaultFields
    .filter((field) => !fieldSettings?.disabledFields.includes(field.key))
    .map((field) => {
      const base = {
        fieldKey: field.key,
        label: t(field.labelKey),
        required: field.locked,
      };

      switch (field.key) {
        case 'referral':
          return {
            ...base,
            control: ControlType.Select,
            options: leadTypes
              .filter((leadType) => !leadType.deleted)
              .map((leadType) => ({
                label: leadType.name,
                value: leadType.uid,
              })),
          };
        case 'title':
          return {
            ...base,
            control: ControlType.Select,
            options: TitleOptions.map((op) => {
              const option = TitleData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'address':
          return { ...base, control: ControlType.Address };
        case 'phoneNumber':
        case 'secondaryPhoneNumber':
        case 'emergencyContactNumber':
          return { ...base, control: ControlType.TextField, type: InputType.Tel };
        case 'emailAddress':
          return { ...base, control: ControlType.TextField, type: InputType.Email };
        case 'dob':
          return { ...base, control: ControlType.DatePicker };
        case 'gender':
          return {
            ...base,
            control: ControlType.Select,
            options: GenderOptions.map((op) => {
              const option = GenderData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'maritalStatus':
          return {
            ...base,
            control: ControlType.Select,
            options: MaritalStatusOptions.map((op) => {
              const option = MaritalStatusData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'contactPreference':
          return {
            ...base,
            control: ControlType.Select,
            options: ContactMethodOptions.map((op) => {
              const option = ContactMethodData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'contactPermissions':
          return {
            ...base,
            control: ControlType.CheckboxGroup,
            options: ContactMethodOptions.map((op) => {
              const option = ContactMethodData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        default:
          return { ...base, control: ControlType.TextField, type: InputType.Text };
      }
    });

  const customFormFields: ISharedField[] = fieldSettings?.customFields
    .filter((field) => !fieldSettings?.disabledFields.includes(field.key))
    .map((field) => ({
      fieldKey: field.key,
      control: ControlType.TextField,
      type: InputType.Text,
      label: field.label,
      required: false,
    }));

  const submit = async (data: IAddEditPatientFormOutput) => {
    setSubmitting(true);
    try {
      const actor = getActionTimestampFromUser(userData);
      const { referral, title, fullName, phoneNumber, ...rest } = data;
      const payload: IPatientDao = {
        referral,
        title,
        fullName,
        phoneNumber,
        uid: uuidv4(),
        created: actor,
        updated: actor,
        organisationUid: userData!.organisationUid!,
        ...(rest.address && { address: rest.address }),
        ...(rest.secondaryPhoneNumber && { secondaryPhoneNumber: rest.secondaryPhoneNumber }),
        ...(rest.emailAddress && { emailAddress: rest.emailAddress }),
        ...(rest.dob && { dob: Timestamp.fromDate(rest.dob.toDate()) }),
        ...(rest.gender && { gender: rest.gender }),
        ...(rest.maritalStatus && { maritalStatus: rest.maritalStatus }),
        ...(rest.emergencyContactName && { emergencyContactName: rest.emergencyContactName }),
        ...(rest.emergencyContactNumber && { emergencyContactNumber: rest.emergencyContactNumber }),
        ...(rest.gpDetails && { gpDetails: rest.gpDetails }),
        ...(rest.contactPreference && { contactPreference: rest.contactPreference }),
        ...(rest.contactPermissions && { contactPermissions: rest.contactPermissions }),
        ...(fieldSettings.customFields && {
          customFields: fieldSettings.customFields
            .filter((field) => data[field.key] !== undefined)
            .map((field) => ({
              key: field.key,
              value: data[field.key] as string,
            })),
        }),
      };
      await PatientApiService.set(payload);
      setSubmitting(false);
      dialog?.closeDialog();
      message.success(t('patients.add_edit_patient.create.success'));
    } catch (error) {
      setSubmitting(false);
      message.error(t('patients.add_edit_patient.create.error'));
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditPatientFormOutput>
        className='overflow-y-auto p-4'
        onFinish={submit}
        fields={[...configurableDefaultFormFields, ...customFormFields]}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-patient-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.add_edit_patient.create.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditPatientDialog;
